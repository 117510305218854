import {createTheme, Theme} from '@mui/material/styles';
import {globalConfig} from '@/Helpers/globalConfig';

declare module '@mui/material/styles' {
    interface Palette {
        black?: Palette['primary'];
    }

    interface PaletteOptions {
        black?: PaletteOptions['primary'];
    }
}

const getTheme = (): Theme => {
    return createTheme({
        palette: {
            primary: {
                main:
                    globalConfig.system === 'development'
                        ? '#c91db2'
                        : globalConfig.system === 'testing' || globalConfig.system === 'staging'
                            ? '#c9981d'
                            : '#afc91d', // mode === 'production'
            },
            success: {
                main: '#58a618',
            },
            warning: {
                main: '#fde800',
            },
            info: {
                main: '#3287a8',
            },
            secondary: {
                main: '#f0f4fa',
            },
            error: {
                main: '#d32f2f',
            },
            black: {
                main: '#010101',
                light: '#010101',
                dark: '#010101',
                contrastText: '#fafafa',
            },
        },
        typography: {
            fontFamily: 'Roboto, NotoSans, sans-serif',
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#fafafa',
                    },
                },
            },
        },
    });
};

export default getTheme;
