import React from 'react';
import {SubmitFunction} from 'formifly';
import {IconButtonWithTooltip} from '@common/butterfly-shared-react-library';
import {Button, Card, CardHeader, Grid, Typography} from '@mui/material';
import {DeleteOutlined, EditOutlined} from '@mui/icons-material';
import {TFunction} from 'i18next';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import {
    ActionAreaContainer,
    ActionButtonContainer,
    MarginGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import {
    getConfirmTotpDeviceShape,
    getCreateTotpDeviceShape,
    getDeleteTotpDeviceShape,
    getEditTotpDeviceShape,
} from '@/Areas/Customer/Helpers/CustomerHelpers';
import TotpModals from '@/Areas/Customer/Components/TotpModals';
import CardContentWithoutPadding from '@/Components/Layout/CardContentWithoutPadding';
import {confirmTotpDeviceType, totpDeviceType} from '@/Areas/Customer/Data/Customer';
import {SecuritySuccessState} from '@/Areas/Customer/Data/Security';

type TotpOverviewPropType = {
    t: TFunction<['common', 'account', 'auth', 'formifly']>,
    totpDevices: totpDeviceType[],
    setTotpDevices: React.Dispatch<React.SetStateAction<totpDeviceType[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setAlert: React.Dispatch<React.SetStateAction<any>>,
    deleteLastDevice: boolean,
    setSuccessState: React.Dispatch<React.SetStateAction<SecuritySuccessState>>,
};

const TotpOverview = (props: TotpOverviewPropType): React.JSX.Element => {
    const {
        t,
        totpDevices,
        setLoading,
        setAlert,
        deleteLastDevice,
        setSuccessState,
    } = props;

    const [createTotpModal, setCreateTotpModal] = React.useState(false);
    const [deleteTotpDevice, setDeleteTotpDevice] = React.useState<totpDeviceType>();
    const [editTotpDevice, setEditTotpDevice] = React.useState<totpDeviceType>();
    const [confirmTotpDevice, setConfirmTotpDevice] = React.useState<confirmTotpDeviceType>();

    const [customerPassword, setCustomerPassword] = React.useState<any>();
    const [confirmationAlert, setConfirmationAlert] = React.useState<any>({show: false, message: '', severity: 'info'});

    const createTotpDeviceShape = getCreateTotpDeviceShape();
    const deleteTotpDeviceShape = getDeleteTotpDeviceShape();
    const editTotpDeviceShape = getEditTotpDeviceShape();
    const confirmTotpDeviceShape = getConfirmTotpDeviceShape();

    const handleDeleteTotpModalClose = (): void => {
        setDeleteTotpDevice(undefined);
    };

    const handleCreateTotpDeviceSubmit: SubmitFunction<typeof createTotpDeviceShape> = (values, setErrors): void => {
        if (values!.label) {
            callWithJwt('/customers/self/password-verify', 'POST', {password: values!.password})
                .then(() => {
                    callWithJwt('/totp-devices', 'POST', {name: values!.label})
                        .then((response) => {
                            // @ts-ignore
                            if (response!.data!.secret !== undefined) {
                                setCustomerPassword(values!.password);
                                setConfirmTotpDevice(response.data as confirmTotpDeviceType);
                                setCreateTotpModal(false);
                            } else {
                                console.warn('TOTP device creation response returned invalid data');
                                setAlert({show: true, message: 'security.error.totp.create', severity: 'error'});
                            }
                        })
                        .catch((reason): void => {
                            if (reason.data.error.code) {
                                if (reason.data.error.code === 'totp_device_entry_limit_reached') {
                                    console.warn('TOTP device creation failed, too many devices');
                                    setCreateTotpModal(false);
                                    setLoading(true);
                                    setAlert({
                                        show: true,
                                        message: 'security.error.totp.too_many_devices',
                                        severity: 'error',
                                    });
                                }
                            } else {
                                console.error('Could not create TOTP device', reason);
                                setAlert({show: true, message: 'security.error.totp.create', severity: 'error'});
                            }
                        });
                })
                .catch((reason) => {
                    if (reason.data.error.code === 'invalid_password') {
                        console.warn('Given password was invalid');
                        setErrors({password: t('auth:error.invalid_password')});
                    } else {
                        setAlert({show: true, message: 'security.error.totp.create', severity: 'error'});
                        setConfirmTotpDevice(undefined);
                        setCreateTotpModal(false);
                        setLoading(true);
                        console.error('Could not confirm password', reason);
                    }
                });


        }

    };

    const handleTotpEdit = (device: any): void => {
        setEditTotpDevice(device);
    };

    const handleTotpDelete = (device: any): void => {
        setDeleteTotpDevice(device);
    };

    const handleConfirmTotpDeviceSubmit: SubmitFunction<typeof createTotpDeviceShape> = (values, setErrors): void => {
        if (confirmTotpDevice && values) {
            if (values.totp) {
                callWithJwt(
                    '/totp-devices/' + String(confirmTotpDevice.id) + '/enable',
                    'POST',
                    {
                        password: customerPassword,
                        totp: values.totp,
                    })
                    .then(() => {
                        setAlert({show: true, message: 'account:security.totp.creation.success', severity: 'success'});
                        setLoading(true);
                        setCustomerPassword(undefined);
                        setConfirmTotpDevice(undefined);
                        setSuccessState({open: true, which: 'totp', what: 'creation'});
                    })
                    .catch((reason): void => {
                        console.warn('failed to confirm TOTP', reason);
                        if (reason && reason.data) {
                            if (reason.data.error.code === 'totp_device_invalid_totp') {
                                console.warn('Invalid TOTP given');
                                setErrors({totp: t('auth:error.invalid_totp')});
                            } else if (reason.data.error.code === 'validation_error') {
                                console.warn('Invalid data in TOTP field');
                                setErrors({totp: t('auth:error.invalid_totp')});
                            }
                        } else {
                            setConfirmationAlert({
                                show: true,
                                message: 'account:security.error.totp.confirmation',
                                severity: 'error',
                            });
                        }
                    });
            }
        }
    };

    const handleConfirmTotpDeviceClose = (): void => {
        if (confirmTotpDevice) {
            callWithJwt('/totp-devices/' + String(confirmTotpDevice.id), 'DELETE', {password: customerPassword})
                .then(() => {
                    setAlert({show: true, message: 'account:security.totp.confirmation.close', severity: 'info'});
                })
                .catch((reason) => {
                    console.error('Could not delete TOTP device: ', reason);
                    setAlert({
                        show: true,
                        message: 'account:security.error.totp.confirmation_close',
                        severity: 'error',
                    });
                })
                .finally(() => {
                    setConfirmTotpDevice(undefined);
                    setLoading(true);
                    setCustomerPassword(undefined);
                });
        }
    };

    const handleEditTotpDeviceSubmit: SubmitFunction<typeof editTotpDeviceShape> = (values): void => {
        if (editTotpDevice) {
            if (values && values!.label) {
                callWithJwt('/totp-devices/' + String(editTotpDevice.id), 'PATCH', {name: values!.label})
                    .then(() => {
                        setEditTotpDevice(undefined);
                        setSuccessState({open: true, which: 'totp', what: 'editing'});
                    })
                    .catch((reason) => {
                        console.error('Could not edit TOTP device: ', reason);
                        setEditTotpDevice(undefined);
                        setAlert({show: true, message: 'account:security.error.totp.edit', severity: 'error'});
                    })
                    .finally(() => {
                        setLoading(true);
                    });
            }
        }
    };

    const handleDeleteTotpDeviceSubmit: SubmitFunction<typeof deleteTotpDeviceShape> = (values, setErrors): void => {
        if (deleteTotpDevice) {
            if (values && values!.password) {
                callWithJwt('/totp-devices/' + String(deleteTotpDevice.id), 'DELETE', {password: values.password})
                    .then(() => {
                        setAlert({show: true, message: 'account:security.totp.deletion.success', severity: 'success'});
                        setDeleteTotpDevice(undefined);
                        setSuccessState({open: true, which: 'totp', what: 'deletion'});
                        setLoading(true);
                    })
                    .catch((reason) => {
                        console.error('Could not delete TOTP device: ', reason);
                        if (reason.data.error.code === 'auth_failed') {
                            setErrors({
                                password: t('auth:error.invalid_password'),
                                password_repeat: t('auth:error.invalid_password'),
                            });
                        } else {
                            setAlert({show: true, message: 'account:security.error.totp.delete', severity: 'error'});
                            setDeleteTotpDevice(undefined);
                            setLoading(true);
                        }
                    });
            }
        }
    };

    return <>
        <Card>
            <CardHeader title={t('account:security.totp.header')}/>
            <CardContentWithoutPadding>
                <Grid container>
                    <MarginGrid item xs={12}>
                        <Typography>
                            {t('account:security.totp.info')}
                        </Typography>
                    </MarginGrid>
                    {totpDevices!.length === 0
                        ? <></>
                        : <MarginGrid container spacing={2}>
                            {totpDevices!.map((device, index) => <Grid item xs={12} key={index}>
                                <Card>
                                    <TextWrappableCardHeader title={device.name}
                                                             subheader={t('account:security.created', {when: new Date(device.created).toDateString()})}
                                                             action={
                                                                 <ActionAreaContainer>
                                                                     <ActionButtonContainer>
                                                                         <IconButtonWithTooltip
                                                                             onClick={() => handleTotpEdit(device)}
                                                                             label={t('account:security.totp.edit')}>
                                                                             <EditOutlined/>
                                                                         </IconButtonWithTooltip>
                                                                         <IconButtonWithTooltip
                                                                             onClick={() => handleTotpDelete(device)}
                                                                             label={t('account:security.totp.delete')}>
                                                                             <DeleteOutlined/>
                                                                         </IconButtonWithTooltip>
                                                                     </ActionButtonContainer>
                                                                     <Typography variant="body2">
                                                                         {
                                                                             device.enabled
                                                                                 ? t('account:security.device.enabled')
                                                                                 : t('account:security.device.disabled')
                                                                         }
                                                                     </Typography>
                                                                 </ActionAreaContainer>}/>
                                </Card>
                            </Grid>)}
                        </MarginGrid>}
                    {totpDevices.length < 2 && <MarginGrid item xs={12}>
                        <Button onClick={() => setCreateTotpModal(true)}
                                variant="contained"
                                fullWidth>
                            {t('account:security.totp.add')}
                        </Button>
                    </MarginGrid>}
                </Grid>
            </CardContentWithoutPadding>
        </Card>
        <TotpModals t={t}
                    createTotpModal={createTotpModal}
                    setCreateTotpModal={setCreateTotpModal}
                    createTotpDeviceShape={createTotpDeviceShape}
                    handleCreateTotpDeviceSubmit={handleCreateTotpDeviceSubmit}
                    handleConfirmTotpDeviceClose={handleConfirmTotpDeviceClose}
                    deleteTotpDevice={deleteTotpDevice}
                    handleDeleteTotpModalClose={handleDeleteTotpModalClose}
                    deleteTotpDeviceShape={deleteTotpDeviceShape}
                    handleDeleteTotpDeviceSubmit={handleDeleteTotpDeviceSubmit}
                    editTotpDevice={editTotpDevice}
                    setEditTotpDevice={setEditTotpDevice}
                    handleEditTotpDeviceSubmit={handleEditTotpDeviceSubmit}
                    editTotpDeviceShape={editTotpDeviceShape}
                    confirmTotpDeviceShape={confirmTotpDeviceShape}
                    handleConfirmTotpDeviceSubmit={handleConfirmTotpDeviceSubmit}
                    confirmTotpDevice={confirmTotpDevice}
                    deleteLastDevice={deleteLastDevice}
                    confirmationAlert={confirmationAlert}/>
    </>;
};

export default TotpOverview;
