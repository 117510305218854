import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {Card} from '@mui/material';
import PageHeader from '@/Components/PageHeader';

const AuthContainerHeaderContainer = styled.div`
    display: flex;
    justify-content: start;
    align-self: center;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.values.sm as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }

    @media (min-width: ${props => props.theme.breakpoints.values.md as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }
`;

export const AuthContainerHeader = (props: any): ReactElement => {
    const {title} = props;
    return <AuthContainerHeaderContainer>
        {/* @ts-ignore */}
        <PageHeader title={title}/>
    </AuthContainerHeaderContainer>;
};

const AuthContainer = styled(Card)`
    font-family: sans-serif;
    align-self: center;

    @media (min-width: ${props => props.theme.breakpoints.values.sm as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }

    @media (min-width: ${props => props.theme.breakpoints.values.md as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }
`;

export default AuthContainer;
