import React from 'react';
import styled from 'styled-components';
import {FormiflyForm} from 'formifly';
import {Trans, useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {Alert, AlertColor, Button, Card, Grid} from '@mui/material';
import {Login} from '@mui/icons-material';
import {fetchResponseWithRetry} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';
import CardContentWithoutPadding from '@/Components/Layout/CardContentWithoutPadding';
import {getRegistrationVerifyShape} from '@/Areas/Auth/Data/Auth';
import PageHeader from '@/Components/PageHeader';

type VerificationPropType = {
    verification_token?: string,
}

const Verification = (props: VerificationPropType): React.JSX.Element => {
    const {verification_token} = props;
    const {t} = useTranslation(['auth', 'common']);
    const location = useLocation();

    let register: boolean = false;
    if (location.state) {
        register = location.state.register === true;
    }

    const [alert, setAlert] = React.useState<{
        show: boolean,
        message: string,
        severity: string
    }>({
        show: false,
        message: '',
        severity: 'info',
    });

    const [showVerificationInfo, setShowVerificationInfo] = React.useState<boolean>(true);
    const [verificationToken, setVerificationToken] = React.useState<string | undefined>(verification_token);

    const verificationShape = getRegistrationVerifyShape(t);

    const handleVerifyFormSubmit = (values: {verify_token: string}): void => {
        setVerificationToken(values.verify_token);
    };

    React.useEffect(() => {
        if (verificationToken) {
            fetchResponseWithRetry(
                (globalConfig.customerApiUrl as string) + '/auth/signup-verify',
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({reset_token: verificationToken}),
                },
            )
                .then((response) => {
                    if (response.status === 204) {
                        setShowVerificationInfo(false);
                        setAlert({show: true, message: 'verification.success', severity: 'success'});
                    }
                })
                .catch((reason) => {
                    if (reason) {
                        if (reason.data.error.code === 'invalid_password_reset_token') {
                            setShowVerificationInfo(true);
                            setAlert({show: true, message: 'error.invalid_verification_token', severity: 'error'});
                        } else if (reason.data.error.code === 'already_verified') {
                            setShowVerificationInfo(false);
                            setAlert({show: true, message: 'error.already_verified', severity: 'error'});
                        } else {
                            setShowVerificationInfo(false);
                            setAlert({show: true, message: 'error.server_error', severity: 'error'});
                            console.error('An unexpected error occurred.', reason);
                        }
                    } else {
                        setShowVerificationInfo(false);
                        setAlert({show: true, message: 'error.server_error', severity: 'error'});
                        console.error('An unexpected error occurred without a reason.');
                    }
                });
        }
    }, [verificationToken]);

    React.useEffect(() => {
        if (register) {
            setAlert({show: true, message: 'verification.register', severity: 'info'});
        }
    }, [register]);

    return <>
        <PageHeader title={t('auth:verification.header')}/>
        <Card>
            <CardContentWithoutPadding>
                {alert.show && <Grid item xs={12}>
                    <Alert severity={alert.severity as AlertColor}>
                        <Trans t={t as any} ns="auth">{alert.message}</Trans>
                    </Alert>
                </Grid>}
                {showVerificationInfo
                    ? <>
                        <p>
                            {t('auth:verification.info')}
                            <br/>
                            {t('auth:verification.info_2')}
                            <br/>
                            {t('auth:verification.info_3')}
                            <br/>
                            <a href={'mailto:' + t('common:contact.email') as string}>{t('common:contact.email')}</a>
                        </p>
                        <hr/>
                        <p>
                            {t('auth:verification.form_info')}
                        </p>
                        <FormiflyForm onSubmit={handleVerifyFormSubmit as any} shape={verificationShape}>
                            <FormContentWrapper>
                                <FormiflyMuiField name="verify_token" label={t('auth:verification.form_label')}/>
                                <Button color="primary"
                                        type="submit"
                                        variant="contained">
                                    {t('auth:verification.form_submit')}
                                </Button>
                            </FormContentWrapper>
                        </FormiflyForm>
                    </>
                    : <ButtonWrapper>
                        <Button color="primary"
                                variant="outlined"
                                to="/login"
                                component={Link}
                                startIcon={<Login/>}>
                            {t('auth:verification.proceed')}
                        </Button>
                    </ButtonWrapper>}
            </CardContentWithoutPadding>
        </Card>
    </>;
};

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
`;

const FormContentWrapper = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: column;
`;

export default Verification;
