import React from 'react';
import styled from 'styled-components';
import {Modal, ModalProps} from '@mui/material';

const CommonModal = (props: ModalProps): React.JSX.Element => {
    return <Modal {...props} component={ModalRoot}>{props.children}</Modal>;
};

const ModalRoot = styled('div')`
    overflow: visible;
    max-width: 98vw;
    margin: 1rem auto auto auto;

    @media (min-width: ${props => props.theme.breakpoints.values.md as string}px) {
        max-width: 600px;
        margin: 4rem auto auto auto;
    }
`;

export default CommonModal;
