import {BooleanValidator, ObjectValidator, StringValidator} from 'formifly';
import i18n, {TFunction} from 'i18next';
import punycode from 'punycode/punycode';
import {PasswordRepeatValidator, PunycodeEmailValidator} from '@common/butterfly-shared-react-library';
import {globalConfig} from '@/Helpers/globalConfig';
import {bufferDecode, bufferEncode} from '@/Areas/Customer/Helpers/CustomerHelpers';

const emailRegexp = /.+@.+/;

export const getRegistrationShape = (t: TFunction<any>): ObjectValidator<any> => {
    return new ObjectValidator({
        email: new PunycodeEmailValidator().required(t('auth:error.email_required')),
        language: new StringValidator(i18n.language.toUpperCase()).required(),
        password: new StringValidator().minLength(10).maxLength(4096).required(t('auth:error.password_required')),
        password_repeat: new PasswordRepeatValidator('password'),
        important: new StringValidator(),
    });
};

export const getRegistrationVerifyShape = (t: TFunction<any>): ObjectValidator<any> => {
    return new ObjectValidator({
        verify_token: new StringValidator().required(t('error.verification_token_required')),
    });
};

export const getLoginShape = (t: TFunction<any>): ObjectValidator<any> => {
    return new ObjectValidator({
        email: new PunycodeEmailValidator().required(t('error.email_required')),
        password: new StringValidator().required(t('error.password_required')),
        stay_logged_in: new BooleanValidator(),
    });
};

export const getWebAuthNLoginShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        stay_logged_in_webauthn: new BooleanValidator(),
    });
};

export const getTotpLoginShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        totp: new StringValidator()
            .minLength(6)
            .maxLength(6)
            .required(),
    });
};

export const makeLoginRequest = async (email: string, password: string, stayLoggedIn: boolean, totp?: string): Promise<any> => {
    if (emailRegexp.test(email)) {
        email = punycode.toASCII(email);
    }
    const response = await fetch(globalConfig.customerApiUrl as string + '/auth', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            email: email,
            password: password,
            totp: totp ?? undefined,
            stay_logged_in: stayLoggedIn,
        }),
    });
    if (response.ok) {
        return response.json();
    } else {
        return Promise.reject(await response.json());
    }
};

export const makeWebAuthNLoginRequest = async (stayLoggedIn: boolean, mediation: CredentialMediationRequirement = 'required', abortController?: AbortController): Promise<any> => {
    const authCredential = await createAuthCredential(mediation, abortController)
        .then((response) => response)
        .catch((reason) => {
            return Promise.reject(reason);
        });

    return await fetch(globalConfig.customerApiUrl as string + '/auth/webauthn', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            credential: authCredential,
            stay_logged_in: stayLoggedIn,
        }),
    })
        .then(response => {
            return response.json();
        })
        .catch((error: any) => {
            return Promise.reject(error);
        });
};

type authOptionsType = {
    challenge?: string | Uint8Array,
    allowCredentials?: any[],
}

const fetchAuthOptions = (): Promise<authOptionsType> => {
    return fetch(globalConfig.customerApiUrl as string + '/auth/webauthn/options', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
    })
        .then(response => response.json())
        .catch((reason) => {
            console.error('Error fetching auth options: ', reason);
            return Promise.reject(reason);
        });
};

export const createAuthCredential = async (mediation: CredentialMediationRequirement = 'required', abortController?: AbortController): Promise<any> => {
    const authOptions: authOptionsType = await fetchAuthOptions();

    if (authOptions && authOptions.challenge && Array.isArray(authOptions.allowCredentials)) {
        authOptions.challenge = bufferDecode(authOptions.challenge);

        authOptions.allowCredentials.forEach((listItem: any) => {
            listItem.id = bufferDecode(listItem.id);
        });

        return navigator.credentials.get({
            publicKey: authOptions as any,
            mediation: mediation,
            signal: abortController?.signal ?? undefined,
        })
            .then((credentialContainer: any | null) => {
                if (credentialContainer) {
                    return {
                        id: credentialContainer.id,
                        authenticatorAttachment: credentialContainer.authenticatorAttachment,
                        rawId: bufferEncode(credentialContainer.rawId),
                        type: credentialContainer.type,
                        response: {
                            authenticatorData: bufferEncode(credentialContainer.response.authenticatorData),
                            clientDataJSON: bufferEncode(credentialContainer.response.clientDataJSON),
                            signature: bufferEncode(credentialContainer.response.signature),
                            userHandle: bufferEncode(credentialContainer.response.userHandle),
                        },
                    };
                } else {
                    return Promise.reject('could not create credential');
                }
            })
            .catch((reason) => {
                console.error('Error getting credential: ', reason);
                return Promise.reject('error getting credential');
            });
    } else {
        console.error('Error getting Authentication Options');
        return Promise.reject('could not get auth options');
    }
};
