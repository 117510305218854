import React from 'react';
import {Alert, Card, CardContent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import PageHeader from '@/Components/PageHeader';
import CardText from '@/Components/CardText';

const Home = (): React.JSX.Element => {
    const {t} = useTranslation(['home', 'common', 'auth', 'account']);
    const {state} = useLocation();

    const [showLogoutInfoBox, setShowLogoutInfoBox] = React.useState(false);
    const [showDeleteInfoBox, setShowDeleteInfoBox] = React.useState(false);

    React.useEffect(() => {
        if (state) {
            if (state.logout) {
                setShowLogoutInfoBox(true);
            }
            if (state.accountDelete) {
                setShowDeleteInfoBox(true);
            }
        }
        window.history.replaceState({}, '', '/');
    }, [state]);

    return <>
        {showLogoutInfoBox
            ? <Alert severity="success">{t('auth:logout_successful')}</Alert>
            : <></>}
        {showDeleteInfoBox
            ? <Alert severity="success">{t('account:settings.delete_successful')}</Alert>
            : <></>}
        <PageHeader title={t('header')}/>
        <Card>
            <CardContent>
                <CardText>
                    {t('blurb')}
                    <br/>
                    <br/>
                    {t('more_info')}
                </CardText>
                <LinkContainer>
                    <Link href="https://www.gls-mobility.de/e-mobilitaet/giro-e/nutzersicht/" target="_blank">
                        <LinkImg src="/img/info-user.jpg" alt=""/>
                        <span>{t('user_perspective')}</span>
                    </Link>
                    <Link href="https://www.gls-mobility.de/e-mobilitaet/giro-e/betreibersicht/" target="_blank">
                        <LinkImg src="/img/info-operator.jpg" alt=""/>
                        <span>{t('operator_perspective')}</span>
                    </Link>
                    <Link href="https://www.gls-mobility.de/neuigkeiten/blog" target="_blank">
                        <LinkImg src="/img/news.jpg" alt=""/>
                        <span>{t('news')}</span>
                    </Link>
                </LinkContainer>
            </CardContent>
        </Card>
    </>;
};

const LinkContainer = styled.div`
    display: grid;

    @media (max-width: ${props => props.theme.breakpoints.values.sm as string}px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: none;
    }

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: none;
`;

const Link = styled.a`
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;

    max-width: 200px;
    margin-left: auto;
    margin-right: auto;

    & span {
        text-align: center;
        color: ${props => props.theme.palette.text.primary as string};
    }

    &:hover img {
        border-color: #70d31e;
    }

    &:hover span {
        text-decoration: underline;
        color: #8daa0b;
    }
`;

const LinkImg = styled.img`
    border: 2px solid ${props => props.theme.palette.primary.main};
    border-radius: 50%;
    width: 100%;
    height: auto;
`;

export default Home;
