import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

const LoadingCircle = (props: {test_id?: string}): React.JSX.Element => {
    return <Backdrop open={true} invisible={true}>
        <CircularProgress data-testid={props.test_id ?? 'main-circular-progress'}/>
    </Backdrop>;
};

export default LoadingCircle;
