import React from 'react';
import {FormiflyForm} from 'formifly';
import i18n from 'i18next';
import styled from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Alert, AlertColor, Card, CardContent, CardHeader, Grid} from '@mui/material';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {getAccountEditShape} from '@/Areas/Customer/Helpers/CustomerHelpers';
import PageHeader from '@/Components/PageHeader';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {FlexGrowButton, SubmitCancelGrid} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import {useUserContext} from '@/Contexts/User/UserContext';
import {getLanguageOptions} from '@/Helpers/localisationHelpers';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import FormiflyCountrySelect from '@/Components/Formifly/FormiflyCountrySelect';

const AccountEdit = (): React.JSX.Element => {
    const {t} = useTranslation(['common', 'account', 'formifly']);
    const {user} = useUserContext();

    const navigate = useNavigate();

    const accountEditShape = getAccountEditShape();
    const languageOptions = getLanguageOptions(t);

    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    let defaultValues = {};
    if (user) {
        defaultValues = {
            email: user.email,
            language: user.language,
            name: user?.name ?? '',
            company: user?.company ?? '',
            address: user?.address ?? '',
            address_additional: user?.address_additional ?? '',
            postal_code: user?.postal_code ?? '',
            locality: user?.locality ?? '',
            country: user?.country ?? '',
            vat_id: user?.vat_id ?? '',
            tax_number: user?.tax_number ?? '',
            customer_reference_number: user?.customer_reference_number ?? '',
        };
    }

    const handleEditSubmit = (values: AccountEditSubmitType): void => {
        if (values) {
            values.email = undefined;
            callWithJwt('/customers/self', 'PATCH', values)
                .then((response) => {
                    if (response.response.status === 200) {
                        navigate('/account/settings', {state: {saved: true}});
                        return Promise.resolve(true);
                    } else {
                        return Promise.reject(response);
                    }
                })
                .catch((reason) => {
                    setAlert({show: true, message: 'settings.error.edit_save', severity: 'error'});
                    console.error('Error editing customer: ', reason);
                });
        }
    };

    return <>
        <PageHeader title={t('account:settings.header.edit')}/>
        {alert.show &&
            <Grid item xs={12}>
                <Alert severity={alert.severity as AlertColor}>
                    <Trans t={t as any} ns="account">{alert.message}</Trans>
                </Alert>
            </Grid>}
        <Card>
            <CardContent>
                <FormiflyForm onSubmit={handleEditSubmit as any} shape={accountEditShape} defaultValues={defaultValues} t={t}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardHeader title={t('account:settings.edit_headers.personal')}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormiflyMuiField name="name"
                                              label={t('account:name')}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormiflyMuiField name="language"
                                              label={t('common:language') as string}
                                              options={languageOptions}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="email"
                                              label={t('common:email')}
                                              help={t('account:settings.email_help')}
                                              wrapHelp
                                              disabled/>
                        </Grid>
                        <Grid item xs={12}>
                            <CardHeader title={t('account:settings.edit_headers.invoice')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="company"
                                              label={t('account:company')}
                                              help={t('account:company_help')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="address"
                                              label={t('account:address')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormiflyMuiField name="address_additional"
                                              label={t('account:address_additional')}/>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormiflyMuiField name="locality"
                                              label={t('account:locality')}/>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <FormiflyMuiField name="postal_code"
                                              label={t('account:postal_code')}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormiflyCountrySelect name="country"
                                                   label={t('account:country')}
                                                   lang={i18n.language}/>
                        </Grid>
                        <MuiHelpFieldGrid item xs={12} md={12} lg={4}>
                            <FormiflyMuiField name="customer_reference_number"
                                              label={t('account:customer_reference_number')}/>
                        </MuiHelpFieldGrid>
                        <Grid item xs={12}>
                            <CardHeader title={t('account:settings.edit_headers.tax')}/>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <MuiHelpFieldGrid item xs={12} md={6} lg={4}>
                                <FormiflyMuiField name="vat_id"
                                                  label={t('account:vat_id')}/>
                            </MuiHelpFieldGrid>
                            <MuiHelpFieldGrid item xs={12} md={6} lg={4}>
                                <FormiflyMuiField name="tax_number"
                                                  label={t('account:tax_number')}/>
                            </MuiHelpFieldGrid>
                        </Grid>
                        <SubmitCancelGrid item xs={12}>
                            <FlexGrowButton type="submit"
                                            variant="contained">
                                {t('account:settings.button.edit')}
                            </FlexGrowButton>
                            {/* @ts-ignore */}
                            <FlexGrowButton component={Link}
                                            to="/account/settings"
                                            color="error"
                                            variant="contained">
                                {t('common:cancel')}
                            </FlexGrowButton>
                        </SubmitCancelGrid>
                    </Grid>
                </FormiflyForm>
            </CardContent>
        </Card>
    </>;
};

type AccountEditSubmitType = {
    name: string,
    company: string,
    address: string,
    address_additional: string,
    postal_code: string,
    locality: string,
    country: string,
    vat_id: string,
    tax_number: string,
    customer_reference_number: string,
    language: string,
    email?: string,
}

const MuiHelpFieldGrid = styled(Grid)`
    & > div > .MuiGrid-grid-xs-6 {
        max-width: 49%;
    }
`;

export default withLoginRequirement(AccountEdit);
