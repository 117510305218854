import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';
import {Alert, Card, CardActionArea, CardHeader, Grid} from '@mui/material';
import {AccountCircle, HelpOutline, Payments, ReceiptLong, RecentActors} from '@mui/icons-material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import PageHeader from '@/Components/PageHeader';
import {totpResponseType, webAuthNResponseType} from '@/Areas/Customer/Data/Customer';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import PasskeyLogo from '@/Components/PasskeyLogo';

const AccountOverview = (): React.JSX.Element => {
    const {t} = useTranslation(['account', 'common']);
    const navigate = useNavigate();
    const [noSecureLoginAvailable, setNoSecureLoginAvailable] = React.useState(false);
    const leftPage = React.useRef(false);

    const checkIfSecureLoginAvailable = async (): Promise<void> => {
        const totpAvailable = await callWithJwt<totpResponseType>('/totp-devices?limit=1', 'GET')
            .then((response) => {
                return Promise.resolve(response?.data?.total_count > 0);
            })
            .catch((reason) => {
                console.error('TOTP Device fetch failed: ', reason);
                return Promise.resolve(true);
            });

        const webauthnAvailable = await callWithJwt<webAuthNResponseType>('/webauthn-devices?limit=1', 'GET')
            .then((response) => {
                return Promise.resolve(response?.data?.total_count > 0);
            })
            .catch((reason) => {
                console.error('WebAuthN Device fetch failed: ', reason);
                return Promise.resolve(true);
            });

        if (!leftPage.current) {
            setNoSecureLoginAvailable(!(totpAvailable || webauthnAvailable));
        }
    };

    React.useEffect(() => {
        void checkIfSecureLoginAvailable();

        return () => {
            leftPage.current = true;
        };
    }, []);

    return <>
        <PageHeader title={t('account:overview.header')}/>
        <Grid container spacing={2}>
            {noSecureLoginAvailable && <Grid item xs={12}>
                <PasskeyAlert icon={<PasskeyLogo size={'4rem'}/>}>
                    {t('account:overview.passkey_nag_1')}<br/>
                    {t('account:overview.passkey_nag_2')}<br/>
                    {t('account:overview.passkey_nag_3')}
                    <Link to="/account/settings">{t('account:overview.passkey_nag_link')}</Link>
                    {t('account:overview.passkey_nag_post_link')}
                </PasskeyAlert>
            </Grid>}
            <Grid item xs={12} md={6} lg={3}>
                <AccountOverviewCard title={t('account:overview.invoice_header')}
                                     subheader={t('account:overview.invoice_help')}
                                     icon={ReceiptLong}
                                     link="/account/invoice"
                                     navigate={navigate}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AccountOverviewCard title={t('account:overview.bank_account_header')}
                                     subheader={t('account:overview.bank_account_help')}
                                     icon={Payments}
                                     link="/account/payment/bank"
                                     navigate={navigate}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AccountOverviewCard title={t('account:overview.access_token_header')}
                                     subheader={t('account:overview.access_token_help')}
                                     icon={RecentActors}
                                     link="/account/payment/token"
                                     navigate={navigate}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AccountOverviewCard title={t('account:overview.account_header')}
                                     subheader={t('account:overview.account_help')}
                                     icon={AccountCircle}
                                     link="/account/settings"
                                     navigate={navigate}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AccountOverviewCard title={t('account:overview.support_header')}
                                     subheader={t('account:overview.support_help')}
                                     icon={HelpOutline}
                                     link="/support"
                                     navigate={navigate}/>
            </Grid>
        </Grid>
    </>;
};
const AccountOverviewCard = (
    props: {
        title: string,
        subheader: string,
        icon: React.FunctionComponent<{sx: {width: string, height: string}}>,
        link: string,
        navigate: NavigateFunction,
    },
): React.JSX.Element => {
    const Icon = props.icon;
    const navigate = props.navigate;

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement, MouseEvent>,
    ): void => {
        event.preventDefault();
        navigate(props.link);
    };

    return <AccountTileCard>
        {/* @ts-ignore */}
        <AccountTileCardActionArea href={props.link} onClick={handleClick}>
            <CardHeaderContainer>
                <CardHeaderWrapper>
                    <CardHeader title={props.title} subheader={props.subheader}/>
                </CardHeaderWrapper>
                <CardHeaderIconWrapper>
                    <Icon sx={{width: '1.6em', height: '1.6em'}}/>
                </CardHeaderIconWrapper>
            </CardHeaderContainer>
        </AccountTileCardActionArea>
    </AccountTileCard>;
};

const CardHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-areas: "icon card-header";
    min-height: 7em;
`;

const CardHeaderIconWrapper = styled.div`
    grid-area: icon;
    align-self: start;
    justify-self: center;
    padding: .8em 0 0 1em;
    display: flex;
`;

const CardHeaderWrapper = styled.div`
    grid-area: card-header;
    place-self: start;
`;

const AccountTileCard = styled(Card)`
    height: 100%;
`;

const AccountTileCardActionArea = styled(CardActionArea)`
    height: 100%;
`;

const PasskeyAlert = styled(Alert)`
    background-color: ${(props) => props.theme.palette.black.main};
    color: ${(props) => props.theme.palette.black.contrastText};
`;

export default withLoginRequirement(AccountOverview);
