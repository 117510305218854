import React from 'react';
import {useParams} from 'react-router';
import Verification from '@/Areas/Auth/Views/Verification';

const VerificationWithToken = (): React.JSX.Element => {
    const {verification_token} = useParams();

    return <Verification verification_token={verification_token}/>;
};

export default VerificationWithToken;
