import {FormiflyMuiField, IconButtonWithTooltip} from '@common/butterfly-shared-react-library';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import React from 'react';
import {TFunction} from 'i18next';

export type PasswordInputType = {
    name: string,
    label: string,
    t: TFunction<any>,
    autocomplete?: string,
    autoFocus?: boolean,
    inputProps?: any,
    help?: any,
    wrapHelp?: any,
};

const PasswordInputField = (props: PasswordInputType): React.JSX.Element => {
    const [passwordShown, setPasswordShown] = React.useState(false);

    const {name, label, t, autocomplete, inputProps, autoFocus, help, wrapHelp} = props;

    return <FormiflyMuiField name={name}
                             label={label}
                             autoFocus={autoFocus}
                             type={passwordShown ? 'text' : 'password'}
                             autocomplete={autocomplete}
                             help={help}
                             wrapHelp={wrapHelp}
        /* @ts-ignore */
                             unit={<IconButtonWithTooltip label={t('auth:show_password')}
                                                          onClick={() => setPasswordShown(!passwordShown)}>
                                 {passwordShown ? <VisibilityOff/> : <Visibility/>}
                             </IconButtonWithTooltip>}
                             inputProps={inputProps}/>;
};

export default PasswordInputField;
