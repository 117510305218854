import React from 'react';
import styled from 'styled-components';
import HeaderWithButtonWrapper from '@/Components/HeaderWithButtonWrapper';

type PageHeaderPropType = {
    title: string,
    action?: React.JSX.Element,
}

const PageHeader = (props: PageHeaderPropType): React.JSX.Element => {
    const {title, action} = props;

    if (action) {
        return <HeaderWithButtonWrapper>
            <H1Header>{title}</H1Header>
            {action}
        </HeaderWithButtonWrapper>;
    }

    return <H1Header>{title}</H1Header>;
};

const H1Header = styled.h1`
    margin: 0;
    font-weight: 400;
    font-size: 1.5rem;
    padding-bottom: .5rem;
`;

export default PageHeader;
