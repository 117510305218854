import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardContent} from '@mui/material';
import {useParams} from 'react-router-dom';
import withLoginRequirement from '@/Components/withLoginRequirement';
import TokenForm from '@/Areas/Payment/Helpers/TokenForm';
import type {Token} from '@/Areas/Payment/Data/Token';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import CardText from '@/Components/CardText';
import PageHeader from '@/Components/PageHeader';

const TokenEdit = (): React.JSX.Element => {
    const [token, setToken] = React.useState<Token>();
    const [error, setError] = React.useState(false);
    const {t} = useTranslation(['payment', 'formifly', 'common', 'error']);
    const {id} = useParams();

    React.useEffect(() => {
        callWithJwt<Token>('/tokens/' + String(id), 'GET')
            .then((response) => {
                setToken(response.data);
            })
            .catch((reason) => {
                console.error('Could not fetch token', reason);
                setError(true);
            });
    }, []);


    if (error) {
        return <>
            <PageHeader title={t('error:heading')}/>
            <Card>
                <CardContent>
                    <CardText>{t('payment:token.fetch_failed')}</CardText>
                </CardContent>
            </Card>
        </>;
    }

    if (!token) {
        return <CenteredCircularProgress/>;
    }

    return <>
        <PageHeader title={t('payment:token.add')}/>
        <Card>
            <CardContent>
                <TokenForm edit={true} t={t} submitUrl={'/tokens/' + String(id)} default={token}/>
            </CardContent>
        </Card>
    </>;
};

export default withLoginRequirement(TokenEdit as any);
