import React from 'react';
import {useNavigate} from 'react-router-dom';
import {clearStorage, getItem} from '@/Helpers/localStorageHelpers';
import {callWithJwt, doLogoutRequest, refreshJwt} from '@/Helpers/jwtHelpers';
import {useInterval} from '@/Helpers/useInterval';
import {User} from '@/Areas/Customer/Data/Customer';

export enum LoggedInState {
    NotYetKnown = 'NOT_YET_KNOWN',
    LoggedIn = 'LOGGED_IN',
    LoggedOut = 'LOGGED_OUT',
}

export type UserContextType = {
    loggedInState: LoggedInState;
    user?: User;
    setUser: React.SetStateAction<User>;
    attemptLogout: () => Promise<void>;
    clearUserData: () => void;
    fetchAndSetUserData: () => Promise<void>;
    setLoggedInState: (state: LoggedInState) => React.SetStateAction<LoggedInState>;
};

export const UserContext = React.createContext({});
UserContext.displayName = 'UserContext';

export const UserProvider = (props: {children: React.JSX.Element[] | React.JSX.Element}): React.JSX.Element => {
    const [loggedInState, setLoggedInState] = React.useState<LoggedInState>(LoggedInState.NotYetKnown);
    const [user, setUser] = React.useState<User | undefined>(undefined);

    const navigate = useNavigate();

    const attemptLogout = (): Promise<any> => {
        return doLogoutRequest()
            .catch((reason) => {
                console.warn('Server side logout failed. Deleting client side data only', reason);
            })
            .finally(() => {
                setLoggedInState(LoggedInState.LoggedOut);
                clearStorage();
                setUser(undefined);
                navigate('/', {state: {logout: true}});
            });
    };

    // Helper function to clear user data in cases where the user cannot be logged out, like account deletion
    const clearUserData = (): void => {
        clearStorage();
        setUser(undefined);
        setLoggedInState(LoggedInState.NotYetKnown);
    };

    const fetchAndSetUserData = async (): Promise<void> => {
        return callWithJwt<User>('/customers/self', 'GET')
            .then((result) => {
                if (!result.data || result.response.status !== 200) {
                    return Promise.reject(result);
                }

                setUser(result.data);
                return Promise.resolve();
            })
            .catch(async (reason) => {
                console.error('Could not fetch user', reason);
                await attemptLogout();
                return Promise.reject();
            });
    };

    const refreshJwtAndSetLoginState = (): void => {
        const token: string | undefined = getItem('jwt', undefined);
        if (!token) {
            setLoggedInState(LoggedInState.LoggedOut);
        } else {
            refreshJwt()
                .then(() => {
                    fetchAndSetUserData()
                        .then(() => {
                            setLoggedInState(LoggedInState.LoggedIn);
                        })
                        .catch(() => {
                            setLoggedInState(LoggedInState.LoggedOut);
                        });
                })
                .catch(() => {
                    void attemptLogout();
                });
        }
    };

    useInterval(
        (): void => {
            if (loggedInState === LoggedInState.LoggedIn) {
                refreshJwtAndSetLoginState();
            }
        },
        600000,
    );

    React.useEffect(() => {
        refreshJwtAndSetLoginState();

        // Testing environment workaround, isn't ran in browsers.
        if (typeof process === 'object') {
            refreshJwtAndSetLoginState();
        }

        document.addEventListener('visibilitychange', () => {
            refreshJwtAndSetLoginState();
        });

        return () => {
            document.removeEventListener('visibilitychange', () => undefined);
        };
    }, []);

    const value = {
        user,
        setUser,
        loggedInState,
        setLoggedInState,
        attemptLogout,
        fetchAndSetUserData,
        clearUserData,
    };

    return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
    const context = React.useContext(UserContext);
    if (Object.keys(context).length === 0) {
        console.error('UserContext is undefined. Was useUserContext called within a UserProvider?');
    }
    return context as UserContextType;
};
