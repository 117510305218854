import React from 'react';
import {TFunction} from 'i18next';
import {Button, CardContent, CardHeader, Modal} from '@mui/material';
import styled from 'styled-components';
import {CheckCircleOutlined} from '@mui/icons-material';
import ModalBody from '@/Components/Layout/ModalBody';

type SuccessModalProps = {
    t: TFunction<['common', 'account', 'auth', 'formifly']>,
    open: boolean,
    onClose: () => void,
    which: 'totp' | 'webauthn',
    what: 'deletion' | 'editing' | 'creation',
}

const SuccessModal = (props: SuccessModalProps): React.JSX.Element => {
    const {t, open, onClose, which, what} = props;

    if (open) {
        return <Modal open={true} onClose={onClose}>
            <ModalBody>
                <CardHeader title={t('account:security.' + which + '.' + what + '.header' as any, {context: 'simple'})}/>
                <SuccessContainer>
                    <SuccessIcon/>
                    <span>{t('account:security.' + which + '.' + what + '.success' as any)}</span>
                    <br/>
                    <Button fullWidth onClick={onClose} color="success" variant="contained">
                        {t('common:close_modal')}
                    </Button>
                </SuccessContainer>
            </ModalBody>
        </Modal>;
    }

    return <></>;
};

const SuccessContainer = styled(CardContent)`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
`;

const SuccessIcon = styled(CheckCircleOutlined)`
    font-size: 2rem;
`;

export default SuccessModal;
