import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardContent} from '@mui/material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import TokenForm from '@/Areas/Payment/Helpers/TokenForm';
import PageHeader from '@/Components/PageHeader';

type TokenAddProps = {
    auth?: string,
}

export const TokenAddWithoutHoc = (props: TokenAddProps): React.JSX.Element => {
    const {t} = useTranslation(['payment', 'formifly', 'common']);

    return <>
        <PageHeader title={t('payment:token.add')}/>
        <Card>
            <CardContent>
                <TokenForm edit={false} t={t} auth={props.auth} submitUrl="/tokens"/>
            </CardContent>
        </Card>
    </>;
};

const TokenAddWithHoc = withLoginRequirement(TokenAddWithoutHoc as any);
export default TokenAddWithHoc;
