import i18n, {TFunction} from 'i18next';

export const getCurrentLocale = (): string => {
    return getLocale(i18n.language);
};

export const getLocale = (language: string | undefined): string => {
    switch (language) {
        case 'en':
            return 'en-GB';
        case 'de':
            return 'de-DE';
        case 'nl':
            return 'nl-NL';
        case 'fr':
            return 'fr-FR';
        default:
            return 'de-DE';
    }
};

export const getLanguageOptions = (t: TFunction<any>): Array<{label: string, value: string}> => {
    return [
        {
            label: t('common:language_selection.german'),
            value: 'DE',
        },
        {
            label: t('common:language_selection.english'),
            value: 'EN',
        },
        {
            label: t('common:language_selection.french'),
            value: 'FR',
        },
        {
            label: t('common:language_selection.dutch'),
            value: 'NL',
        },
    ];
};

// Untestable in a node environment
/* v8 ignore next 5 */
export const fetchCountries = (lang: string): Promise<any> => {
    return fetch('/countries/' + lang + '/countries.json')
        .then((response) => {
            return response.json();
        });
};

export const getCountryName = async (lang: string, countryCode: string): Promise<string> => {
    return fetchCountries(lang)
        .then((response: [{id: number, alpha2: string, alpha3: string, name: string}]) => {
            const countryObject = response
                .find((value) => {
                    return value.alpha2 === countryCode;
                });
            if (countryObject !== undefined) {
                return countryObject.name;
            } else {
                return Promise.reject();
            }
        })
        .catch((reason) => {
            console.warn('Fetching countries failed: ', reason);
            return Promise.reject();
        });
};
