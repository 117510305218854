import React from 'react';
import {fetchCountries} from '@/Helpers/localisationHelpers';

export const fetchCountriesForSelect = (
    lang: string,
    setOptions: React.Dispatch<React.SetStateAction<SelectFieldOptions>>,
): void => {
    fetchCountries(lang)
        .then((countries: [countryEntryType]) => {
            const newOptions: SelectFieldOptions = [];
            countries.map((entry: {name: string, alpha2: string, alpha3: string, id: number}) => {
                newOptions.push({label: entry.name as string, value: entry.alpha2});
            });
            setOptions(newOptions);
        })
        .catch(reason => console.error('Fetching Countries failed:', reason));
};

type countryEntryType = {
    name: string,
    alpha2: string,
    alpha3: string,
    id: number
};

export type SelectFieldOptions = Array<{
    value: any,
    label: string,
    disabled?: boolean,
}>
