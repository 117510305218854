import React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Alert} from '@mui/material';
import {ChargeConnectorStatus, Pricegroup, SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {ConnectorError, PaymentError, PricegroupError} from '@/Areas/Connector/Data/ConnectorTypes';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';
import {StyledCard} from '@/Components/Layout/Common';
import WalleePayment from '@/Areas/Connector/Components/WalleePayment';
import ConnectorPricing from '@/Areas/Connector/Components/ConnectorPricing';
import ConnectorInfo from '@/Areas/Connector/Components/ConnectorInfo';
import {fetchConnector} from '@/Areas/Connector/Helpers/ConnectorHelpers';
import ErrorDisplay from '@/Areas/Connector/Components/ErrorDisplay';
import {useSocketContext} from '@/Contexts/Socket/SocketContext';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';

const Connector = (): React.JSX.Element => {
    const {connectorUid} = useParams();
    const [connector, setConnector] = React.useState<SimplifiedConnectorWithLocationInfo>();
    const [loading, setLoading] = React.useState(false);
    const [pricegroup, setPricegroup] = React.useState<Pricegroup>();
    const [error, setError] = React.useState<ConnectorError | PricegroupError | PaymentError>();
    const [processingPayment, setProcessingPayment] = React.useState(false);
    const [transactionHasFailed, setTransactionHasFailed] = React.useState(false);
    const updateSubscription = React.useRef<number>();
    const leftPage = React.useRef(false);
    const {t} = useTranslation(['connector', 'chargepoint', 'wallee']);
    const [searchParams, setSearchParams] = useSearchParams();

    const {subscribeToUpdates, unsubscribeFromUpdates, online} = useSocketContext();

    const fetchPricegroup = (id: number): Promise<void> => {
        return fetchWithRetry<Pricegroup>(
            (globalConfig.customerApiUrl as string) + '/pricegroups/' + String(id),
            withCredentialsAndContentType({method: 'GET'}),
        )
            .then((fetchedPricegroup: Pricegroup) => {
                setPricegroup(fetchedPricegroup);
            })
            .catch((reason: Error) => {
                console.error('Could not fetch pricegroup', reason);
                setError(PricegroupError.NETWORK_ERROR);
            });
    };

    React.useEffect((): void => {
        if (connector) {
            if (connector.creditcard_pricegroup_id === undefined || connector.creditcard_pricegroup_id === null) {
                setError(PricegroupError.NOT_AVAILABLE);
            } else {
                setLoading(true);
                fetchPricegroup(connector.creditcard_pricegroup_id)
                    .finally(() => setLoading(false));
            }
        }
    }, [connector]);

    React.useEffect(() => {
        const pendingParam = searchParams.get('pendingTransaction');
        if (connector !== undefined) {
            if (pendingParam && connector.status === ChargeConnectorStatus.AVAILABLE) {
                setProcessingPayment(true);
                /* c8 ignore next 6 */
            } else if (pendingParam) {
                // This makes little sense to test as it would be completely mocked away
                searchParams.delete('pendingTransaction');
                setSearchParams(searchParams);
                setProcessingPayment(false);
            }
        }
    }, [connector]);

    React.useEffect(() => {
        if (searchParams.get('failedTransaction')) {
            setTransactionHasFailed(true);
            searchParams.delete('failedTransaction');
            setSearchParams(searchParams);
        }
    }, []);

    React.useEffect(() => {
        if (connectorUid === undefined) {
            setError(ConnectorError.NOT_FOUND);
        } else {
            void fetchConnector(connectorUid, setConnector, setError);
            updateSubscription.current = subscribeToUpdates('charge-connector', (data: any, uid: string) => {
                setConnector(oldConnector => {
                    if (oldConnector && oldConnector.uid === uid) {
                        return {...oldConnector, ...data} as SimplifiedConnectorWithLocationInfo;
                    } else {
                        return oldConnector;
                    }
                });
            });
        }

        return () => {
            leftPage.current = true;
            if (updateSubscription.current !== undefined) {
                unsubscribeFromUpdates('charge-connector', updateSubscription.current);
            }
        };
    }, []);

    if (error) {
        return <ErrorDisplay error={error}/>;
    }

    if (!connector || !pricegroup || loading) {
        return <CenteredCircularProgress data-testid="connector-pricegroup-circular-progress"/>;
    }

    return (
        <>
            {transactionHasFailed && (
                <StyledCard>
                    <Alert severity="error">{t('wallee:error.transaction_not_authorized')}</Alert>
                </StyledCard>
            )}
            {processingPayment && (
                <StyledCard data-testid="connector-processing-payment-card">
                    <Alert severity="success">{t('wallee:transaction_authorized')}</Alert>
                </StyledCard>
            )}
            <ConnectorInfo connector={connector}/>
            <ConnectorPricing pricegroup={pricegroup}/>
            {(
                    connector.status === ChargeConnectorStatus.AVAILABLE ||
                    connector.status === ChargeConnectorStatus.PREPARING ||
                    connector.status === ChargeConnectorStatus.CHARGING
                ) &&
                <WalleePayment connector={connector}/>}
            {online && <div data-testid="is-online"/>}
        </>
    );
};

export default Connector;
