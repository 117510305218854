import React from 'react';
import {TFunction} from 'i18next';
import {Trans} from 'react-i18next';
import styled from 'styled-components';
import {FormiflyForm, ObjectValidator, SubmitFunction} from 'formifly';
import {Alert, AlertColor, CardContent, CardHeader, Grid, Modal, TextField, Typography} from '@mui/material';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import ModalBody from '@/Components/Layout/ModalBody';
import PaddedGridItem from '@/Components/Layout/PaddedGridItem';
import {
    FlexGrowButton,
    MarginGrid,
    SubmitCancelGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';
import QRCode from '@/Areas/Customer/Components/QRCode';


type TotpModalsProps = {
    t: TFunction<['common', 'account', 'auth', 'formifly']>,
    createTotpModal: any,
    setCreateTotpModal: (b: boolean) => void,
    createTotpDeviceShape: any,
    handleCreateTotpDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    deleteTotpDevice: any,
    handleDeleteTotpModalClose: () => void,
    deleteTotpDeviceShape: any,
    handleDeleteTotpDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    editTotpDevice: any,
    setEditTotpDevice: (b: any | undefined) => void,
    editTotpDeviceShape: any,
    handleEditTotpDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    confirmTotpDevice: any,
    handleConfirmTotpDeviceClose: () => void,
    confirmTotpDeviceShape: any,
    handleConfirmTotpDeviceSubmit: SubmitFunction<ObjectValidator<any>>,
    deleteLastDevice: boolean,
    confirmationAlert: any,
}

const TotpModals = (props: TotpModalsProps): React.JSX.Element => {
    const {
        t,
        createTotpModal,
        setCreateTotpModal,
        createTotpDeviceShape,
        handleDeleteTotpDeviceSubmit,
        deleteTotpDevice,
        handleDeleteTotpModalClose,
        deleteTotpDeviceShape,
        handleCreateTotpDeviceSubmit,
        confirmTotpDevice,
        handleConfirmTotpDeviceClose,
        confirmTotpDeviceShape,
        handleConfirmTotpDeviceSubmit,
        editTotpDevice,
        editTotpDeviceShape,
        handleEditTotpDeviceSubmit,
        setEditTotpDevice,
        deleteLastDevice,
        confirmationAlert,
    } = props;

    return <>
        <Modal open={createTotpModal} onClose={() => setCreateTotpModal(false)}>
            <ModalBody>
                <CardHeader title={t('account:security.totp.creation.header')}/>
                <CardContent>
                    <Grid container>
                        <FormiflyForm shape={createTotpDeviceShape} onSubmit={handleCreateTotpDeviceSubmit} t={t}>
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('account:security.totp.creation.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <FormiflyMuiField name="label" label={t('account:security.device.label')} id="label"/>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password"
                                                    label={t('auth:password')}
                                                    autocomplete="current-password"
                                                    t={t}/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit" variant="contained">
                                    {t('account:security.device.create', {what: t('account:security.totp.device')})}
                                </FlexGrowButton>
                                <FlexGrowButton onClick={() => setCreateTotpModal(false)}
                                                color="error"
                                                variant="contained">
                                    {t('common:cancel')}
                                </FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </Modal>

        {confirmTotpDevice &&
            <SmallerModal open={Boolean(confirmTotpDevice)} onClose={() => handleConfirmTotpDeviceClose()}>
                <ModalBody>
                    <CardHeader title={t('account:security.totp.confirmation.header')}/>
                    <CardContent>
                        <Grid container>
                            <MarginGrid item xs={12}>
                                <Typography>{t('account:security.totp.confirmation.info')}</Typography>
                            </MarginGrid>
                            <QRGrid item xs={12}>
                                <QRCode>{confirmTotpDevice.provisioning_url}</QRCode>
                            </QRGrid>
                            <MarginGrid item xs={12}>
                                <TextField defaultValue={confirmTotpDevice.secret}
                                           helperText={t('account:security.totp.confirmation.helper')}
                                           disabled
                                           fullWidth
                                           variant="outlined"/>
                            </MarginGrid>
                            {confirmTotpDevice && <Grid item xs={12}>
                                <FormiflyForm shape={confirmTotpDeviceShape}
                                              onSubmit={handleConfirmTotpDeviceSubmit}
                                              t={t}>
                                    <MarginGrid item xs={12}>
                                        <FormiflyMuiField name="totp"
                                                          label={t('auth:totp')}
                                                          id="totp"
                                                          wrapHelp={false}
                                                          help={t('account:security.totp.confirmation.help')}
                                                          autocomplete="one-time-value"/>
                                    </MarginGrid>
                                    {confirmationAlert.show &&
                                        // @ts-ignore
                                        <PaddedGridItem item xs={12}>
                                            <Alert severity={confirmationAlert.severity as AlertColor}>
                                                <Trans t={t as any} ns="account">{confirmationAlert.message}</Trans>
                                            </Alert>
                                        </PaddedGridItem>}
                                    <SubmitCancelGrid item xs={12}>
                                        <FlexGrowButton type="submit"
                                                        variant="contained">
                                            {t('account:security.device.confirm', {what: t('account:security.totp.device')})}
                                        </FlexGrowButton>
                                        <FlexGrowButton onClick={() => handleConfirmTotpDeviceClose()}
                                                        color="error"
                                                        variant="contained">
                                            {t('common:cancel')}
                                        </FlexGrowButton>
                                    </SubmitCancelGrid>
                                </FormiflyForm>
                            </Grid>}
                        </Grid>
                    </CardContent>
                </ModalBody>
            </SmallerModal>}

        {editTotpDevice && <Modal open={Boolean(editTotpDevice)} onClose={() => setEditTotpDevice(undefined)}>
            <ModalBody>
                <TextWrappableCardHeader
                    title={t('account:security.totp.editing.header', {what: editTotpDevice.name})}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={editTotpDeviceShape} onSubmit={handleEditTotpDeviceSubmit} t={t}>
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('account:security.totp.editing.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <FormiflyMuiField name="label" label={t('account:security.device.label')} id="label"/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit"
                                                variant="contained">
                                    {t('account:security.device.edit')}
                                </FlexGrowButton>
                                <FlexGrowButton onClick={() => setEditTotpDevice(undefined)}
                                                color="error"
                                                variant="contained">
                                    {t('common:cancel')}
                                </FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </Modal>}

        {deleteTotpDevice && <Modal open={Boolean(deleteTotpDevice)} onClose={handleDeleteTotpModalClose}>
            <ModalBody>
                <TextWrappableCardHeader
                    title={t('account:security.totp.deletion.header', {what: deleteTotpDevice.name})}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={deleteTotpDeviceShape} onSubmit={handleDeleteTotpDeviceSubmit} t={t}>
                            {deleteLastDevice
                                && <MarginGrid item xs={12}>
                                    <Alert severity="warning" variant="outlined">
                                        <Typography>
                                            {t('account:security.totp.deletion.info_last')}
                                        </Typography>
                                    </Alert>
                                </MarginGrid>}
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('account:security.totp.deletion.info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password"
                                                    label={t('auth:password')}
                                                    autocomplete="current-password"
                                                    t={t}/>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <PasswordInputField name="password_repeat"
                                                    label={t('auth:password_repeat')}
                                                    autocomplete="current-password"
                                                    t={t}/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit"
                                                variant="contained">{t('account:security.device.delete')}</FlexGrowButton>
                                <FlexGrowButton onClick={() => handleDeleteTotpModalClose()} color="error"
                                                variant="contained">{t('common:cancel')}</FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </Modal>}
    </>;
};

const SmallerModal = styled(Modal)`
    .MuiPaper-root {
        max-width: 1024px;
    }
`;

const QRGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    margin: 1rem 0 1rem;
`;

export default TotpModals;
