import styled from 'styled-components';

const HeaderWithButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    & a {
        height: 2.5rem;
        min-width: fit-content;
    }
`;

export default HeaderWithButtonWrapper;
