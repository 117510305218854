import {CardContent, Container, Paper, Table, TableBody, TableCell, TableContainer, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {AutoCurrencyMoneyDisplay} from '@common/butterfly-shared-react-library';
import {Pricegroup} from '@/Types/ChargeTypes';
import {StyledCard, StyledTableRow} from '@/Components/Layout/Common';
import {PricegroupError} from '@/Areas/Connector/Data/ConnectorTypes';
import {getLocale} from '@/Helpers/localisationHelpers';

const decimalStringToNumberOfSignificantDecimalDigits = (num: string): number => {
    const decimals = num.split('.')[1];
    if (!decimals) {
        return 0;
    }

    return decimals.replace(/0+$/, '').length;
};

const ConnectorPricing = (props: {pricegroup: Pricegroup | PricegroupError}): React.JSX.Element => {
    const {pricegroup} = props;
    const {t, i18n} = useTranslation(['connector', 'chargepoint', 'common']);

    let minimumFractionDigits = 2;

    if (pricegroup && typeof pricegroup !== 'string') {
        minimumFractionDigits = Math.max(
            minimumFractionDigits,
            ...[pricegroup.fee_base, pricegroup.fee_energy, pricegroup.fee_time].map(
                decimalStringToNumberOfSignificantDecimalDigits,
            ),
        );
    }

    const timeFormat = new Intl.DateTimeFormat(getLocale(i18n.language), {
        hour: '2-digit',
        minute: 'numeric',
        timeZone: 'UTC',
    });

    const parseAndFormatTime = (timeString: string): string => {
        return timeFormat.format(Date.parse(`1970-01-01T${timeString}Z`));
    };

    return (
        <>
            {pricegroup && typeof pricegroup !== 'string' && (
                <StyledCard>
                    <CardContent>
                        <Typography paragraph={true}>
                            <strong>{t('connector:fee.root')}</strong>
                        </Typography>
                        <Container maxWidth="xs">
                            <TableContainer component={Paper}>
                                <Table size={'small'}>
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell>
                                                <strong>{t('connector:fee.base')}</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                <AutoCurrencyMoneyDisplay
                                                    amount={pricegroup.fee_base}
                                                    currency={pricegroup.currency}
                                                    roundToDecimalPoints={minimumFractionDigits}
                                                />
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>
                                                <strong>{t('connector:fee.energy')}</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                <AutoCurrencyMoneyDisplay
                                                    amount={pricegroup.fee_energy}
                                                    currency={pricegroup.currency}
                                                    roundToDecimalPoints={minimumFractionDigits}
                                                />
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>
                                                <strong>{t('connector:fee.time')}</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                <AutoCurrencyMoneyDisplay
                                                    amount={pricegroup.fee_time}
                                                    currency={pricegroup.currency}
                                                    roundToDecimalPoints={minimumFractionDigits}
                                                />
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell>
                                                <strong>{t('connector:fee.reserved_amount')}</strong>
                                            </TableCell>
                                            <TableCell align="right">
                                                <AutoCurrencyMoneyDisplay
                                                    amount={pricegroup.reserved_amount}
                                                    currency={pricegroup.currency}
                                                    roundToDecimalPoints={minimumFractionDigits}
                                                />
                                            </TableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                        <p>{t('connector:fee.reserved_explainer')}</p>
                        {(pricegroup.free_time ||
                            pricegroup.fee_time_disable_end !== pricegroup.fee_time_disable_start) && (
                            <p>
                                {pricegroup.free_time > 0 && (
                                    <>{t('connector:fee.free_time',
                                        {
                                            count: pricegroup.free_time / 3600,
                                            amount: (pricegroup.free_time / 3600).toLocaleString(t('common:locale'),
                                                {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 2,
                                                }),
                                        },
                                    )}
                                    </>
                                )}{' '}
                                {pricegroup.fee_time_disable_end !== pricegroup.fee_time_disable_start && (
                                    <>
                                        {t('connector:fee.free_hours', {
                                            context: pricegroup.free_time ? 'followup' : undefined,
                                            range: `${parseAndFormatTime(
                                                pricegroup.fee_time_disable_start,
                                            )}–${parseAndFormatTime(pricegroup.fee_time_disable_end)}`,
                                        })}
                                    </>
                                )}
                            </p>
                        )}
                    </CardContent>
                </StyledCard>
            )}
            {pricegroup === PricegroupError.NOT_AVAILABLE && (
                <StyledCard>
                    <CardContent>{t('connector:no_remote_start')}</CardContent>
                </StyledCard>
            )}
        </>
    );
};

export default ConnectorPricing;
