import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormiflyForm, SubmitFunction} from 'formifly';
import {TableBodyWithoutLastBorder} from '@common/butterfly-shared-react-library';
import {Alert, AlertColor, Button, Card, CardContent, CardHeader, Grid, Table, TableCell, TableRow} from '@mui/material';
import {DeleteForever, Edit, Lock} from '@mui/icons-material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import TotpOverview from '@/Areas/Customer/Components/TotpOverview';
import WebAuthNOverview from '@/Areas/Customer/Components/WebAuthNOverview';
import {totpDeviceType, totpResponseType, webAuthNDeviceType, webAuthNResponseType} from '@/Areas/Customer/Data/Customer';
import SuccessModal from '@/Areas/Customer/Components/SuccessModal';
import {SecuritySuccessState} from '@/Areas/Customer/Data/Security';
import PageHeader from '@/Components/PageHeader';
import {useUserContext} from '@/Contexts/User/UserContext';
import GenericErrorCard from '@/Components/GenericErrorCard';
import {getCountryName} from '@/Helpers/localisationHelpers';
import i18n from '@/Extensions/i18n';
import CommonModal from '@/Components/Layout/CommonModal';
import ModalBody from '@/Components/Layout/ModalBody';
import {FlexGrowButton, MarginGrid, SubmitCancelGrid} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';
import {getDeleteAccountShape} from '@/Areas/Customer/Helpers/CustomerHelpers';

const AccountSettingsOverview = (): React.JSX.Element => {
    const {t} = useTranslation(['common', 'account', 'auth', 'formifly']);

    const navigate = useNavigate();
    const {state} = useLocation();
    const {user, fetchAndSetUserData, clearUserData} = useUserContext();

    const [loading, setLoading] = React.useState(true);

    const [deleteLastDevice, setDeleteLastDevice] = React.useState(false);
    const [supportsWebAuthN, setSupportsWebAuthN] = React.useState(false);

    const [userCountry, setUserCountry] = React.useState('');

    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    const [totpDevices, setTotpDevices] = React.useState<totpDeviceType[]>([]);
    const [webAuthNDevices, setWebAuthNDevices] = React.useState<webAuthNDeviceType[]>([]);

    const [totpLoadError, setTotpLoadError] = React.useState<boolean>(false);
    const [webAuthNLoadError, setWebAuthNLoadError] = React.useState<boolean>(false);

    const [deleteAccount, setDeleteAccount] = React.useState(false);

    const [successState, setSuccessState] = React.useState<SecuritySuccessState>({
        open: false,
        which: 'totp',
        what: 'creation',
    });

    const deleteAccountShape = getDeleteAccountShape();

    const handleAccountDeleteSubmit: SubmitFunction<typeof deleteAccountShape> = (values, setErrors) => {
        callWithJwt('/customers/self/password-verify', 'POST', {password: values!.password})
            .then(() => {
                callWithJwt('/customers/self', 'DELETE')
                    .then(() => {
                        clearUserData();
                        void navigate('/', {state: {accountDelete: true}});
                    })
                    .catch((reason) => {
                        console.warn('Could not delete user', reason);
                    });
            })
            .catch(() => {
                setErrors({password: t('auth:error.invalid_password')});
            });
    };

    const closeSuccessModal = () => {
        setSuccessState({open: false, which: 'totp', what: 'creation'});
    };

    React.useEffect(() => {
        if (loading) {
            if (navigator.credentials) {
                setSupportsWebAuthN(true);
            }

            callWithJwt<totpResponseType>('/totp-devices', 'GET')
                .then((response) => {
                    if (response.data.total_count !== 0) {
                        setTotpDevices(response.data.items);
                    } else {
                        setTotpDevices([]);
                    }
                    return Promise.resolve();
                })
                .catch((reason) => {
                    console.error('TOTP Device fetch failed: ', reason);
                    setTotpLoadError(true);
                });

            callWithJwt<webAuthNResponseType>('/webauthn-devices', 'GET')
                .then((response) => {
                    if (response.data.total_count !== 0) {
                        setWebAuthNDevices(response.data.items);
                    } else {
                        setWebAuthNDevices([]);
                    }
                    return Promise.resolve();
                })
                .catch((reason) => {
                    console.error('WebAuthN Device fetch failed: ', reason);
                    setWebAuthNLoadError(true);
                });

            setLoading(false);
        }
    }, [loading]);

    React.useEffect(() => {
        if ((webAuthNDevices.length === 0 && totpDevices.length === 1)
            || (webAuthNDevices.length === 1 && totpDevices.length === 0)) {
            setDeleteLastDevice(true);
        } else {
            setDeleteLastDevice(false);
        }
    }, [webAuthNDevices, totpDevices]);

    React.useEffect(() => {
        if (totpLoadError && !webAuthNLoadError) {
            setAlert({show: true, message: 'account:security.error.totp.fetch', severity: 'error'});
        } else if (webAuthNLoadError && !totpLoadError) {
            setAlert({show: true, message: 'account:security.error.webauthn.fetch', severity: 'error'});
        } else if (totpLoadError && webAuthNLoadError) {
            setAlert({show: true, message: 'account:security.error.fetch', severity: 'error'});
        }
    }, [totpLoadError, webAuthNLoadError]);

    React.useEffect(() => {
        if (state) {
            if (state.saved) {
                fetchAndSetUserData()
                    .catch(reason => {
                        console.warn('Fetching user data after editing failed.', reason);
                    });
                setAlert({show: true, message: 'account:settings.save_successful', severity: 'success'});
            } else if (state.password_saved) {
                setAlert({show: true, message: 'account:settings.password_save_successful', severity: 'success'});
            }
        }
    }, [state]);

    React.useEffect(() => {
        if (user?.country) {
            getCountryName(i18n.language, user.country)
                .then((result) => {
                    setUserCountry(result);
                })
                .catch((reason) => {
                    console.warn('Fetching countries failed', reason);
                });
        }
    }, [user, i18n.language]);

    if (loading) {
        return <CenteredCircularProgress/>;
    }

    if (!user) {
        return <GenericErrorCard/>;
    }

    return <>
        <PageHeader title={t('account:settings.header.primary')}/>
        {alert.show &&
            <Alert severity={alert.severity as AlertColor}>
                <Trans t={t as any} ns="account">{alert.message}</Trans>
            </Alert>}
        <AccountViewAndActionsWrapper>
            <Card>
                <CardContent>
                    <Table>
                        <TableBodyWithoutLastBorder>
                            {Boolean(user.name) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:name')}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.name}
                                    </NarrowerTableCell>
                                </TableRow>}
                            <TableRow>
                                <TableCell>
                                    {t('common:email')}
                                </TableCell>
                                <NarrowerTableCell>
                                    {user.email}
                                </NarrowerTableCell>
                            </TableRow>
                            {user.language !== undefined &&
                                <TableRow>
                                    <TableCell>
                                        {t('common:language') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {t('common:language_short.' + user.language.toLowerCase() as string, t('common:unknown'))}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.company) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:company') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.company}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.address) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:address') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.address}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.address_additional) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:address_additional') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.address_additional}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.locality) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:locality') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.locality}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.postal_code) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:postal_code') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.postal_code}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(userCountry) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:country') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {userCountry}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.tax_number) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:tax_number') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.tax_number}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.vat_id) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:vat_id') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.vat_id}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.customer_reference_number) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:customer_reference_number') as string}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.customer_reference_number}
                                    </NarrowerTableCell>
                                </TableRow>}
                            {Boolean(user.created) &&
                                <TableRow>
                                    <TableCell>
                                        {t('account:created')}
                                    </TableCell>
                                    <NarrowerTableCell>
                                        {user.created}
                                    </NarrowerTableCell>
                                </TableRow>}
                        </TableBodyWithoutLastBorder>
                    </Table>
                </CardContent>
            </Card>
            <div>
                <StickyCard>
                    <CardContent>
                        <AccountActionContainer>
                            <Button component={Link}
                                    to="/account/edit"
                                    variant="outlined"
                                    startIcon={<Edit/>}>
                                {t('account:settings.button.edit')}
                            </Button>
                            <Button component={Link}
                                    to="/account/change-password"
                                    variant="outlined"
                                    startIcon={<Lock/>}>
                                {t('account:settings.button.password')}
                            </Button>
                            {/*
                            <Button component={Link}
                                    to=""
                                    variant="outlined"
                                    startIcon={<Download/>}>
                                {t('account:settings.button.download')}
                            </Button>
                            */}
                            <Button onClick={() => setDeleteAccount(true)}
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteForever/>}>
                                {t('account:settings.button.delete')}
                            </Button>
                        </AccountActionContainer>
                    </CardContent>
                </StickyCard>
            </div>
        </AccountViewAndActionsWrapper>
        <PageHeader title={t('common:account.security')}/>
        <OverviewCardWrapper>
            <TotpOverview t={t}
                          totpDevices={totpDevices}
                          setTotpDevices={setTotpDevices}
                          setLoading={setLoading}
                          setAlert={setAlert}
                          deleteLastDevice={deleteLastDevice}
                          setSuccessState={setSuccessState}/>
            <div>
                <WebAuthNOverview t={t}
                                  webAuthNDevices={webAuthNDevices}
                                  setLoading={setLoading}
                                  supportsWebAuthN={supportsWebAuthN}
                                  setAlert={setAlert}
                                  deleteLastDevice={deleteLastDevice}
                                  setSuccessState={setSuccessState}/>
            </div>
        </OverviewCardWrapper>
        <SuccessModal t={t}
                      onClose={closeSuccessModal}
                      open={successState.open}
                      what={successState.what}
                      which={successState.which}/>
        <CommonModal open={deleteAccount}>
            <ModalBody>
                <CardHeader title={t('account:settings.header.delete')}/>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {t('account:settings.delete.warning')}
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <p>
                                {t('account:settings.delete.info')}
                                <br/>
                                <br/>
                                {t('account:settings.delete.confirm')}
                                <br/>
                                {t('account:settings.delete.confirm_2')}
                            </p>
                        </Grid>
                        <Grid container item xs={12}>
                            <FullwidthFormiflyForm t={t} onSubmit={handleAccountDeleteSubmit as any} shape={deleteAccountShape}>
                                <MarginGrid item xs={12}>
                                    <PasswordInputField name="password"
                                                        label={t('auth:password')}
                                                        autocomplete="current-password"
                                                        t={t}/>
                                </MarginGrid>
                                <SubmitCancelGrid item xs={12}>
                                    <FlexGrowButton type="submit"
                                                    variant="contained">
                                        {t('account:settings.button.delete')}
                                    </FlexGrowButton>
                                    <FlexGrowButton onClick={() => setDeleteAccount(false)}
                                                    color="error"
                                                    variant="contained">
                                        {t('common:cancel')}
                                    </FlexGrowButton>
                                </SubmitCancelGrid>
                            </FullwidthFormiflyForm>
                        </Grid>
                    </Grid>
                </CardContent>
            </ModalBody>
        </CommonModal>
    </>;
};

const OverviewCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    flex-grow: 0;

    @media (min-width: ${props => props.theme.breakpoints.values.md as number}px) {
        flex-direction: row;
        flex-wrap: nowrap;
        & > div {
            flex-basis: 50%;
        }
    }

    & > div {
        overflow: unset;
    }
`;

const AccountViewAndActionsWrapper = styled(OverviewCardWrapper)`
    & > :first-child {
        flex-grow: 1;
    }

    @media (min-width: ${props => props.theme.breakpoints.values.md as number}px) {
        & > :first-child {
            flex-basis: 100%;
        }

        & > :last-child {
            max-width: fit-content;
        }
    }
`;

const AccountActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;

    & :first-child, a, button {
        flex-basis: unset;
        flex-grow: unset;
        align-items: center;
        justify-content: flex-start;
    }
`;

const StickyCard = styled(Card)`
    position: sticky;
    top: 1rem;

    & > :last-child {
        padding-bottom: 16px;
    }
`;

const NarrowerTableCell = styled(TableCell)`
    max-width: 150px;
    overflow-wrap: break-word;
    hyphens: auto;
`;

const FullwidthFormiflyForm = styled(FormiflyForm)`
    width: 100%;
`;

export default withLoginRequirement(AccountSettingsOverview);
