import {ObjectValidator, StringValidator} from 'formifly';
import {PasswordRepeatValidator, PunycodeEmailValidator, VatIDValidator} from '@common/butterfly-shared-react-library';
import {callWithJwt} from '@/Helpers/jwtHelpers';

export const getUserAvatarName = (userName: string | undefined): string => {
    if (userName) {
        const splitUserName = userName.split(' ');
        if (splitUserName.length > 1) {
            return String(userName.split(' ')[0][0] ?? '') + String(userName.split(' ')[splitUserName.length - 1][0] ?? '');
        } else if (userName.length >= 2) {
            return userName[0] + userName[1];
        } else {
            return userName[0];
        }
    } else {
        return '';
    }
};

export const getAccountEditShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        name: new StringValidator().maxLength(180),
        company: new StringValidator().maxLength(180),
        address: new StringValidator().maxLength(180),
        address_additional: new StringValidator().maxLength(180),
        postal_code: new StringValidator().maxLength(16),
        locality: new StringValidator().maxLength(180),
        country: new StringValidator().maxLength(2),
        vat_id: new VatIDValidator(),
        tax_number: new StringValidator().maxLength(16),
        customer_reference_number: new StringValidator().maxLength(180),
        email: new PunycodeEmailValidator().required(),
        language: new StringValidator().required(),
    });
};

export const getCreateTotpDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        label: new StringValidator().maxLength(256).required(),
        password: new StringValidator().required(),
    });
};

export const getCreateWebAuthNDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        label: new StringValidator().maxLength(256).required(),
        password: new StringValidator().required(),
    });
};

export const getDeleteTotpDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        password: new StringValidator().required(),
        password_repeat: new PasswordRepeatValidator('password').required(),
    });
};

export const getDeleteWebAuthNDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        password: new StringValidator().required(),
    });
};

export const getEditTotpDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        label: new StringValidator().maxLength(256).required(),
    });
};

export const getEditWebAuthNDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        label: new StringValidator().maxLength(256).required(),
    });
};

export const getConfirmTotpDeviceShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        totp: new StringValidator()
            .minLength(6)
            .maxLength(6)
            .required(),
    });
};

export const getDeleteAccountShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        password: new StringValidator().required(),
    });
};

export const getPasswordChangeShape = (): ObjectValidator<any> => {
    return new ObjectValidator({
        password_current: new StringValidator().required(),
        password: new StringValidator().minLength(10).maxLength(4096).required(),
        password_repeat: new PasswordRepeatValidator('password').minLength(10).maxLength(4096).required(),
    });
};


// TODO: Move the below two functions to a shared library

// transforms url safe base64 to a Uint8Array of binary data
export const bufferDecode = (value: string | Uint8Array | BufferSource): Uint8Array => {
    // transforms url-safe base64 to base64
    const output = String(value).replace(/_/g, '/').replace(/-/g, '+');

    // transform that base64 into a Uint8Array by individual char codes
    return Uint8Array.from(
        atob(output), // decode given base64 into a byte string
        c => c.charCodeAt(0), // By using this to separate every UTF-16 char code
    );
};

// url-safe base64 encodes a binary stream of data
export const bufferEncode = (value: ArrayBufferLike): string => {
    // transforms binary string generated from the input value
    const output = btoa(
        String.fromCharCode.apply( // Creates a string from the given Array
            null,
            Array.from<number>( // Turns the Uint8Array into an array of numbers
                new Uint8Array(value), // Turns the ArrayBufferLike value into an Uint8Array
            ),
        ),
    );

    // transforms that string to url-safe base64 and strips out any = signs
    return String(output).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

type webAuthNDeviceAtCreation = {
    id: number,
    options: any,
}

// Creates the WebAuthN device
export const createWebAuthNDevice = async (webAuthNDeviceName: string): Promise<webAuthNDeviceAtCreation> => {
    const request_data = {
        name: webAuthNDeviceName,
    };

    return callWithJwt('/webauthn-devices', 'POST', request_data, undefined, 1)
        .then((response) => {
            return response.data as webAuthNDeviceAtCreation;
        });
};

export type CredentialCreationObjectOptionsType = {
    attestation: AttestationConveyancePreference,
    authenticatorSelection: {
        requireResidentKey: boolean,
        residentKey: ResidentKeyRequirement,
        userVerification: UserVerificationRequirement,
    },
    challenge: string | Uint8Array | BufferSource,
    excludeCredentials: any[],
    pubKeyCredParams: PublicKeyCredentialParameters[],
    rp: {
        id: string,
        name: string,
    }
    user: {
        id: string | Uint8Array | BufferSource,
        name: string,
        displayName: string,
    }
}

export type CredentialCreationObjectType = {
    id: number,
    options: CredentialCreationObjectOptionsType,
}

// TODO: Move to shared library
export const createRegistrationCredential = async (credentialCreationOptions: CredentialCreationObjectType): Promise<any> => {
    credentialCreationOptions.options.challenge = bufferDecode(credentialCreationOptions.options.challenge);
    credentialCreationOptions.options.user.id = bufferDecode(credentialCreationOptions.options.user.id);

    return navigator.credentials.create({
        publicKey: credentialCreationOptions.options as any,
    }).then((credentialContainer: any) => {
        return {
            authenticatorAttachment: credentialContainer.authenticatorAttachment,
            id: credentialContainer.id,
            type: credentialContainer.type,
            rawId: bufferEncode(credentialContainer.rawId),
            response: {
                attestationObject: bufferEncode(credentialContainer.response.attestationObject),
                clientDataJSON: bufferEncode(credentialContainer.response.clientDataJSON),
            },
        };
    });
};

type enableWebAuthNDeviceType = {
    data: {
        customer_id: number,
        name: string,
        enabled: boolean,
        credential_id: string,
        credentials_public_key: string,
        sign_count: number,
        id: number,
        created: string,
        modified: string
    }
}

export const enableWebAuthNDevice = (credentialData: any): Promise<enableWebAuthNDeviceType> => {
    // THis credentialContainer will have the rawId, and response, which has the attestation object and clientDataJSON
    return callWithJwt('/webauthn-devices/' + String(credentialData.id) + '/enable', 'POST', credentialData)
        .then((response) => {
            return response as enableWebAuthNDeviceType;
        });
};
