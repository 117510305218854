import React from 'react';
import styled from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {FormiflyForm} from 'formifly';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {Alert, AlertColor, Button, CardContent, Grid, Modal, Typography} from '@mui/material';
import AuthContainer, {AuthContainerHeader} from '@/Areas/Auth/Components/AuthContainer';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';
import {getLoginShape, getTotpLoginShape, getWebAuthNLoginShape, makeLoginRequest, makeWebAuthNLoginRequest} from '@/Areas/Auth/Data/Auth';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';
import {setItem} from '@/Helpers/localStorageHelpers';
import ModalBody from '@/Components/Layout/ModalBody';
import {
    FlexGrowButton,
    MarginGrid,
    SubmitCancelGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import PasskeyLogo from '@/Components/PasskeyLogo';

const Auth = (): React.JSX.Element => {
    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});
    const [abortController, setAbortController] = React.useState<AbortController>();
    const {fetchAndSetUserData, setLoggedInState} = useUserContext();

    const {state} = useLocation();
    const navigate = useNavigate();

    const {t} = useTranslation(['auth', 'common', 'formifly']);

    const shape = getLoginShape(t);
    const webAuthNShape = getWebAuthNLoginShape();
    const totpLoginShape = getTotpLoginShape();

    const [showWebAuthNLogin, setShowWebAuthNLogin] = React.useState(false);
    const [showTotpEntryModal, setShowTotpEntryModal] = React.useState(false);
    const [userData, setUserData] = React.useState<userDataType>(undefined);

    React.useEffect(() => {
        void prepareWebAuth();

        return () => {
            if (abortController) {
                abortController.abort('Left the page.');
            }
        };
    }, []);

    const prepareWebAuth = async (): Promise<void> => {
        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        if (navigator.credentials) {
            setShowWebAuthNLogin(true);

            if (window.PublicKeyCredential
                && PublicKeyCredential.isConditionalMediationAvailable
                && await PublicKeyCredential.isConditionalMediationAvailable()
            ) {
                return makeWebAuthNLoginRequest(false, 'conditional', newAbortController)
                    .then((result) => {
                        return handleLogin(result, false);
                    })
                    .catch((reason) => {
                        console.error('could not log in via conditional UI', reason);
                    });
            }
        }
    };

    const handleLogin = async (result: {access_token: string}, stay_logged_in: boolean): Promise<void> => {
        if (!result.access_token) {
            return Promise.reject();
        } else {
            setItem('jwt', result.access_token);
            setItem('stay_logged_in', stay_logged_in);

            fetchAndSetUserData()
                .then(() => {
                    setLoggedInState(LoggedInState.LoggedIn);
                    if (state !== null) {
                        if (state.redirected_from && state.redirected_from !== '') {
                            navigate(state.redirected_from);
                        } else {
                            navigate('/');
                        }
                    } else {
                        navigate('/');
                    }
                    return Promise.resolve();
                })
                .catch(() => {
                    return Promise.reject();
                });
        }
    };

    const handleSubmit = (values: loginFormSubmitType): void => {
        makeLoginRequest(values.email, values.password, Boolean(values.stay_logged_in))
            .then((result) => {
                return handleLogin(result, Boolean(values.stay_logged_in));
            })
            .catch((reason) => {
                if (reason?.error?.code === 'totp_failed') {
                    setUserData({
                        email: values.email,
                        password: values.password,
                        stay_logged_in: Boolean(values.stay_logged_in),
                    });
                    // The user may also have WebAuthN
                    setShowTotpEntryModal(true);
                } else if (reason?.error?.code === 'auth_failed') {
                    setAlert({show: true, message: 'error.invalid_credentials', severity: 'error'});
                } else if (reason?.error?.code === 'confirmation_required') {
                    navigate('/verify');
                } else {
                    console.error('Login failed: ', reason);
                    setAlert({show: true, message: 'error.unexpected_error', severity: 'error'});
                }
            });
    };

    const handleTotpLoginSubmit =
        (values: totpLoginFormSubmitType, setErrors: React.Dispatch<React.SetStateAction<any>>): void => {
            if (userData) {
                makeLoginRequest(userData.email, userData.password, userData.stay_logged_in, values.totp)
                    .then((result) => {
                        return handleLogin(result, Boolean(userData.stay_logged_in));
                    })
                    .catch((reason) => {
                        if (reason?.error?.code === 'totp_failed') {
                            setErrors({totp: t('auth:error.invalid_totp')});
                        } else {
                            console.error('TOTP Login failed: ', reason);
                            setAlert({show: true, message: 'error.unexpected_error', severity: 'error'});
                            setShowTotpEntryModal(false);
                        }
                    });
            }
        };

    const handleWebAuthNLoginSubmit = (values: webAuthNLoginFormSubmitType): void => {
        if (abortController) {
            abortController.abort('Manual login attempt.');
        }
        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        makeWebAuthNLoginRequest(Boolean(values.stay_logged_in_webauthn), 'required', newAbortController)
            .then((result) => {
                return handleLogin(result, Boolean(values.stay_logged_in_webauthn));
            })
            .catch((reason) => {
                if (reason?.error?.code === 'auth_failed') {
                    console.error('Login failed: ', reason);
                    if (reason.error.message === 'Missing WebAuthN session.') {
                        setAlert({show: true, message: 'error.webauthn_failure_no_account', severity: 'error'});
                    } else if (reason.error.message === 'WebAuthN authentication failed.') {
                        setAlert({show: true, message: 'error.webauthn_failure_unknown', severity: 'error'});
                    } else {
                        setAlert({show: true, message: 'error.webauthn_failure', severity: 'error'});
                    }
                } else {
                    console.error('Login failed:', reason);
                    setAlert({show: true, message: 'error.unexpected_error', severity: 'error'});
                }
            });
    };

    return <>
        <AuthContainerHeader title={t('login')}/>
        <AuthContainer>
            <CardContent>
                <Grid container>
                    {alert.show && <AlertGrid item xs={12}>
                        <Alert severity={alert.severity as AlertColor}>
                            <Trans t={t} ns="auth">{alert.message}</Trans>
                        </Alert>
                    </AlertGrid>}
                    <Grid item>
                        <FormiflyForm onSubmit={handleSubmit as any} shape={shape} t={t as any}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormiflyMuiField name="email"
                                                      label={t('common:email')}
                                                      autoFocus={true}
                                                      autocomplete="email webauthn"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordInputField name="password"
                                                        label={t('password')}
                                                        t={t}
                                                        autocomplete="current-password webauthn"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormiflyMuiField name="stay_logged_in" label={t('stay_logged_in')}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button color="primary" type="submit" variant="contained" fullWidth={true}>
                                        {t('login')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormiflyForm>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>
                    {showWebAuthNLogin
                        ? <Grid item>
                            <FormiflyForm onSubmit={handleWebAuthNLoginSubmit as any}
                                          shape={webAuthNShape}
                                          t={t as any}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button color={'black' as any}
                                                type="submit"
                                                variant="contained"
                                                fullWidth={true}
                                                aria-describedby="webauth-info"
                                                startIcon={<PasskeyLogo/>}>
                                            {t('login_webauthn_submit')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormiflyMuiField name="stay_logged_in_webauthn" label={t('stay_logged_in')}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography id="webauth-info" component="p">
                                            {t('login_webauthn_info')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </FormiflyForm>
                        </Grid>
                        : <BottomAlertGrid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                {t('error.webauthn_unsupported')}
                            </Alert>
                        </BottomAlertGrid>}
                </Grid>
            </CardContent>
        </AuthContainer>
        <Modal open={showTotpEntryModal}>
            <ModalBody>
                <TextWrappableCardHeader title={t('auth:totp_header')}/>
                <CardContent>
                    <Grid item xs={12}>
                        <FormiflyForm shape={totpLoginShape} onSubmit={handleTotpLoginSubmit as any} t={t as any}>
                            <MarginGrid item xs={12}>
                                <Typography>
                                    {t('auth:totp_info')}
                                </Typography>
                            </MarginGrid>
                            <MarginGrid item xs={12}>
                                <FormiflyMuiField name="totp"
                                                  label={t('auth:totp')}
                                                  id="totp"
                                                  autoFocus={true}
                                                  autocomplete="one-time-code"/>
                            </MarginGrid>
                            <SubmitCancelGrid item xs={12}>
                                <FlexGrowButton type="submit"
                                                variant="contained">
                                    {t('auth:totp_submit')}
                                </FlexGrowButton>
                                <FlexGrowButton onClick={() => setShowTotpEntryModal(false)}
                                                color="error"
                                                variant="contained">
                                    {t('common:cancel')}
                                </FlexGrowButton>
                            </SubmitCancelGrid>
                        </FormiflyForm>
                    </Grid>
                </CardContent>
            </ModalBody>
        </Modal>
    </>;
};

const AlertGrid = styled(Grid)`
    padding-bottom: 16px;
`;

const BottomAlertGrid = styled(Grid)`
    padding-top: 1rem;
`;

type loginFormSubmitType = {
    email: string;
    password: string;
    stay_logged_in: string | boolean;
};

type webAuthNLoginFormSubmitType = {
    stay_logged_in_webauthn: string | boolean;
};

type totpLoginFormSubmitType = {
    totp: string;
};

type userDataType = {
    email: string,
    password: string,
    stay_logged_in: boolean
} | undefined;

export default Auth;
