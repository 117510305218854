import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Email, Phone} from '@mui/icons-material';
import {BaseHeadline} from '@/Components/Layout/Common';

const ContactLink = styled.a<{$noPointerEvents?: boolean}>`
    color: #54565c;
    display: block;
    text-decoration: none;
    position: relative;
    padding: 0.3rem 0 0.3125rem 1.75rem;
    pointer-events: ${props => (props.$noPointerEvents ? 'none' : undefined)};

    & svg {
        margin-right: 0.625rem;
        display: inline-block;
        position: absolute;
        text-align: center;
        line-height: 1;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &:hover {
        color: #8daa0b;
        text-decoration: underline;
    }
`;

const ContactWrapper = styled.div`
    width: 60%;
    float: left;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        width: 100%;
    }
`;

const ContactWrap = styled.div`
    height: 100%;
    transition: all 375ms cubic-bezier(0.17, 0.67, 0.53, 1);
`;

const ContactList = styled.div`
    width: 100%;
    box-sizing: border-box;

    &::after {
        clear: both;
        display: block;
    }
`;

const ContactListItem = styled.div`
    float: left;
    width: 50%;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        width: 100%;
    }
`;

const ContactInfoBox = styled.div`
    float: left;
    width: 50%;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        width: 100%;
        padding-bottom: 1rem;
    }
`;

const ContactInfoText = styled.div`
    color: #2c2d2f;
    display: block;
    font-size: medium;
`;

const Contact = (): React.JSX.Element => {
    const {t} = useTranslation(['footer', 'common']);

    return (
        <ContactWrapper>
            <BaseHeadline>{t('contact.root')}</BaseHeadline>
            <ContactWrap>
                <ContactList>
                    <ContactListItem>
                        <ContactLink title={t('contact.contact_link.title')} href={'mailto:' + t('common:contact.email') as string}>
                            <Email/> {t('common:contact.email')}
                        </ContactLink>
                        <ContactLink title={t('contact.contact_link.phone')} href={'tel:' + (t('common:contact.phone') as string).replace(/ /g, '')}>
                            <Phone/>
                            {t('common:contact.phone')}
                        </ContactLink>
                    </ContactListItem>
                </ContactList>
                <div>
                    <ContactInfoBox>
                        <ContactInfoText>
                            {t('contact.address.text.line1')}
                            <br/>
                            {t('contact.address.text.line2')}
                            <br/>
                            {t('contact.address.text.line3')}
                            <br/>
                            {t('contact.address.text.line4')}
                        </ContactInfoText>
                    </ContactInfoBox>
                </div>
            </ContactWrap>
        </ContactWrapper>
    );
};

export default Contact;
