import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {AddRounded, EditRounded} from '@mui/icons-material';
import {IconButtonWithTooltip, LowercaseButton} from '@common/butterfly-shared-react-library';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import type {BankAccount} from '@/Areas/Payment/Data/BankAccount';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import TableRowHeading from '@/Components/Tables/TableRowHeading';
import ConfirmPaymentMethodDeletion from '@/Areas/Payment/Components/ConfirmPaymentMethodDeletion';
import LessPaddedSmallerCardHeader from '@/Components/Layout/LessPaddedSmallerCardHeader';
import {fetchBankAccounts} from '@/Areas/Payment/Helpers/BankAccountHelpers';
import PageHeader from '@/Components/PageHeader';

const BankAccountOverview = (): React.JSX.Element => {
    const [bankAccounts, setBankAccounts] = React.useState<BankAccount[]>();
    const [accountToDelete, setAccountToDelete] = React.useState<BankAccount>();
    const [deletionError, setDeletionError] = React.useState<string>();
    const [error, setError] = React.useState(false);

    const {t} = useTranslation(['common', 'payment', 'error']);

    React.useEffect(() => {
        void fetchBankAccounts(setBankAccounts, setError);
    }, []);

    const handleOpenDeleteConfirm = (deleteWhich: BankAccount): void => {
        setAccountToDelete(deleteWhich);
    };

    const handleCloseDeleteConfirm = (): void => {
        setAccountToDelete(undefined);
        setDeletionError(undefined);
    };

    const handleAccountDeletion = (): void => {
        callWithJwt('/accounts/' + String(accountToDelete!.id), 'DELETE')
            .then(() => {
                setBankAccounts((oldBankAccounts) => {
                    return oldBankAccounts!.filter((bankAccount: BankAccount) => bankAccount.id !== accountToDelete!.id);
                });
                handleCloseDeleteConfirm();
            })
            .catch((reason) => {
                console.error('Failed to delete bank account', reason);
                setDeletionError('deletion_failed');
            });
    };

    if (error) {
        return <Card>
            <CardHeader title={t('error:heading')}/>
            <CardContent>
                <p>{t('payment:account.fetch_failed')}</p>
            </CardContent>
        </Card>;
    }

    if (!bankAccounts) {
        return <CenteredCircularProgress/>;
    }

    return <>
        <PageHeader title={t('payment:account.heading')}
                    action={<Button component={Link}
                                    to={'/account/payment/bank/add'}
                                    endIcon={<AddRounded/>}
                                    variant="outlined">
                        {t('payment:account.add')}
                    </Button>}/>
        {bankAccounts!.length === 0
            ? <Card>
                <CardContent>
                    <p>{t('payment:account.none_found')}</p>
                </CardContent>
            </Card>
            : <Grid container spacing={2}>
                {bankAccounts!.map((bankAccount, index) => <BankAccountEntryGrid item key={index}>
                    <Card>
                        <LessPaddedSmallerCardHeader
                            title={bankAccount.name === '' ? t('payment:bank_account') : bankAccount.name}
                            action={<IconButtonWithTooltip label={t('payment:account.edit')}
                                                           component={Link}
                                                           to={'/account/payment/bank/' + String(bankAccount.id) + '/edit'}>
                                <EditRounded/>
                            </IconButtonWithTooltip>}/>
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableRowHeading>{t('common:iban')}:</TableRowHeading>
                                        <TableCell>{bankAccount.identifier}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableRowHeading>{t('payment:bic')}:</TableRowHeading>
                                        <TableCell>{bankAccount.bic}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableRowHeading>{t('common:internal_comment')}:</TableRowHeading>
                                        <TableCell>{bankAccount.internal_comment}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br/>
                            <LowercaseButton color="error"
                                             variant="contained"
                                             fullWidth
                                             onClick={() => handleOpenDeleteConfirm(bankAccount)}>
                                {t('payment:account.delete')}
                            </LowercaseButton>
                        </CardContent>
                    </Card>
                </BankAccountEntryGrid>)}
            </Grid>}
        <ConfirmPaymentMethodDeletion t={t}
                                      which="account"
                                      error={deletionError}
                                      onClose={handleCloseDeleteConfirm}
                                      onDelete={handleAccountDeletion}
                                      toDelete={accountToDelete}/>
    </>;
};

const BankAccountEntryGrid = styled(Grid)`
    flex-grow: 1;
`;

export default withLoginRequirement(BankAccountOverview);
