import React from 'react';
import styled from 'styled-components';
import {FormiflyForm, SubmitFunction} from 'formifly';
import {Trans, useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {Alert, AlertColor, Card, CardContent, Grid} from '@mui/material';
import {getPasswordChangeShape} from '@/Areas/Customer/Helpers/CustomerHelpers';
import PageHeader from '@/Components/PageHeader';
import withLoginRequirement from '@/Components/withLoginRequirement';
import {FlexGrowButton, SubmitCancelGrid} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import PasswordInputField from '@/Areas/Auth/Components/PasswordInputField';

const PasswordChange = (): React.JSX.Element => {
    const {t} = useTranslation(['common', 'account', 'auth', 'formifly']);

    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    const navigate = useNavigate();

    const passwordChangeShape = getPasswordChangeShape();

    const handlePasswordChangeSubmit: SubmitFunction<typeof passwordChangeShape> = (values, setErrors): void => {
        if (values) {
            callWithJwt('/customers/self/change-password', 'POST', {
                old_password: values.password_current,
                password: values.password,
                password_repeat: values.password_repeat,
            })
                .then(() => {
                    navigate('/account/settings', {state: {password_saved: true}});
                })
                .catch((reason) => {
                    if (reason!.data!.error!.data!.field_errors!.old_password!.code === 'old_password_incorrect') {
                        console.warn('Could not verify password', reason);
                        setErrors({password_current: t('auth:error.invalid_password')});
                    } else if (reason!.data!.error!.data!.field_errors!.password!.code === 'insecure_password') {
                        setErrors({password: t('auth:error.insecure_password')});
                    } else {
                        console.warn('Error changing password: ', reason);
                        setAlert({show: true, message: 'error.unexpected_error', severity: 'error'});
                    }
                });
        }
    };

    return <PageWrapper>
        <PageHeader title={t('account:settings.header.password')}/>
        {alert.show && <Alert severity={alert.severity as AlertColor}>
            <Trans t={t as any} ns="auth">{alert.message}</Trans>
        </Alert>}
        <Card>
            <CardContent>
                <FormiflyForm onSubmit={handlePasswordChangeSubmit as any} shape={passwordChangeShape} t={t}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PasswordInputField name="password_current"
                                                label={t('auth:password_current')}
                                                autocomplete="current-password"
                                                t={t}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInputField name="password"
                                                label={t('auth:password_new')}
                                                help={t('auth:registration.password_help')}
                                                autocomplete="new-password"
                                                wrapHelp={true}
                                                t={t}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordInputField name="password_repeat"
                                                label={t('auth:password_repeat')}
                                                autocomplete="new-password"
                                                t={t}/>
                        </Grid>
                        <SubmitCancelGrid item xs={12}>
                            <FlexGrowButton type="submit"
                                            variant="contained">
                                {t('account:settings.button.password')}
                            </FlexGrowButton>
                            {/* @ts-ignore */}
                            <FlexGrowButton component={Link}
                                            to="/account/settings"
                                            color="error"
                                            variant="contained">
                                {t('common:cancel')}
                            </FlexGrowButton>
                        </SubmitCancelGrid>
                    </Grid>
                </FormiflyForm>
            </CardContent>
        </Card>
    </PageWrapper>;
};

const PageWrapper = styled.div`
    gap: inherit;
    display: inherit;
    flex-direction: inherit;

    align-self: center;

    @media (min-width: ${props => props.theme.breakpoints.values.sm as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }

    @media (min-width: ${props => props.theme.breakpoints.values.md as number}px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number - 20}px;
    }
`;

export default withLoginRequirement(PasswordChange);
