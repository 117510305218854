import React from 'react';
import styled from 'styled-components';
import {TFunction} from 'i18next';
import {Payments, RecentActors, Search, Settings} from '@mui/icons-material';
import {ListItemText, Menu} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {globalConfig} from '@/Helpers/globalConfig';
import {LeftMenuItem, PrimaryColorListItemIcon, UnStyledLink} from '@/Components/MenuComponents';
import AccountMenuItem from '@/Areas/Customer/Components/AccountMenuItem';
import LanguageSelect from '@/Components/Layout/LanguageSelect';
import MyAccountMenuItem from '@/Areas/Customer/Components/MyAccountMenuItem';
import LogoutMenuItem from '@/Areas/Customer/Components/LogoutMenuItem';
import {LoggedInState, useUserContext} from '@/Contexts/User/UserContext';

const NavMenu = (props: {
    smolMenuAnchor: Element | undefined,
    isHorizontalMenu?: boolean,
    closeSmolMenu: () => void,
    handleSearchClick: () => void,
    open?: boolean,
    t: TFunction<any>
}): React.JSX.Element => {
    const {
        smolMenuAnchor,
        open = false,
        closeSmolMenu,
        handleSearchClick,
        isHorizontalMenu = false,
        t,
    } = props;

    const navigate = useNavigate();
    const handlePaymentClick = (): void => {
        navigate('/account/payment/bank');
    };

    const handleTokenClick = (): void => {
        navigate('/account/payment/token');
    };

    const handleSettingsClick = (): void => {
        navigate('/account/settings');
    };

    const {loggedInState} = useUserContext();

    return isHorizontalMenu
        ? <nav>
            <HorizontalMenuList>
                {globalConfig.enableSearch
                    && <LeftMenuItem onClick={handleSearchClick}>
                        <PrimaryColorListItemIcon><Search/></PrimaryColorListItemIcon>
                        <ListItemText>
                            <UnStyledLink to="/search">{t('common:header.receipt')}</UnStyledLink>
                        </ListItemText>
                    </LeftMenuItem>}
                {globalConfig.enableLogin
                    && <AccountMenuItem/>}
                <LanguageSelect/>
            </HorizontalMenuList>
        </nav>
        : <StyledMenu
            open={open}
            anchorEl={smolMenuAnchor}
            onClose={closeSmolMenu}
            disablePortal
            /* @ts-ignore */
            slotProps={{paper: {component: 'nav'}}}
        >
            {globalConfig.enableSearch
                && <LeftMenuItem onClick={handleSearchClick}>
                    <PrimaryColorListItemIcon><Search/></PrimaryColorListItemIcon>
                    <ListItemText><UnStyledLink to="/search">{t('common:header.receipt')}</UnStyledLink></ListItemText>
                </LeftMenuItem>}
            {globalConfig.enableLogin
                && <MyAccountMenuItem/>}
            {loggedInState === LoggedInState.LoggedIn && [
                <LeftMenuItem onClick={handlePaymentClick} key={1}>
                    <PrimaryColorListItemIcon><Payments/></PrimaryColorListItemIcon>
                    <ListItemText>
                        {t('account.payment.bank')}
                    </ListItemText>
                </LeftMenuItem>,
                <LeftMenuItem onClick={handleTokenClick} key={2}>
                    <PrimaryColorListItemIcon><RecentActors/></PrimaryColorListItemIcon>
                    <ListItemText>
                        {t('account.payment.token')}
                    </ListItemText>
                </LeftMenuItem>,
                <LeftMenuItem onClick={handleSettingsClick} key={3}>
                    <PrimaryColorListItemIcon><Settings/></PrimaryColorListItemIcon>
                    <ListItemText>
                        {t('account.settings')}
                    </ListItemText>
                </LeftMenuItem>,
            ]}
            <LanguageSelect/>
            {globalConfig.enableLogin
                && <LogoutMenuItem/>}
        </StyledMenu>;
};

const StyledMenu = styled(Menu)`
    & Button {
        width: 100%;
    }
`;

const HorizontalMenuList = styled.ul`
    display: flex;
    flex-direction: row;
`;

export default NavMenu;
