import React from 'react';
// @ts-ignore
import PasskeySvg from '@svg/passkeyLogo.svg?react';

type PasskeyLogoProps = {
    size?: string,
}

const PasskeyLogo = (props: PasskeyLogoProps): React.JSX.Element => {
    return <PasskeySvg height={props.size ?? '1.5rem'} width={props.size ?? '1.5rem'}/>;
};

export default PasskeyLogo;
