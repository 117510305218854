import React from 'react';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {fetchCountriesForSelect, SelectFieldOptions} from '@/Helpers/countrySelectHelpers';

type FormiflyCountrySelectProps = {
    lang: string,
    label: string,
    name: string,
}

const FormiflyCountrySelect = (props: FormiflyCountrySelectProps): React.JSX.Element => {
    const [options, setOptions] = React.useState<SelectFieldOptions>([]);

    React.useEffect(() => {
        fetchCountriesForSelect(props.lang, setOptions);
    }, [props.lang]);

    return <FormiflyMuiField {...props} options={options} type="select"/>;
};

export default FormiflyCountrySelect;
