import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardContent} from '@mui/material';
import styled from 'styled-components';
import {imageNames} from '@/Areas/NotFound/Data/NotFoundConstants';
import PageHeader from '@/Components/PageHeader';

const CardContentLessPadding = styled(CardContent)`
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
        padding-bottom: 0.5rem;
    }
`;

const PetPhoto = styled.img`
    border-radius: 4px;
`;

const NotFound = (): React.JSX.Element => {
    const {t} = useTranslation(['errorPets', 'common']);

    const imageName = imageNames[Math.floor(Math.random() * imageNames.length)];

    const imageAlt = t(imageName, {
        ns: 'errorPets',
    });

    return <>
        <PageHeader title={t('common:page_not_found.header')}/>
        <Card>
            <CardContentLessPadding>
                <p>{t('common:page_not_found.text')}</p>
                <p>{t('common:page_not_found.pets')}</p>
                <PetPhoto
                    src={`/img/error-pets/${imageName}.webp`}
                    alt={imageAlt}
                    title={imageAlt}
                    width="100%"
                    data-testid={imageName}
                />
            </CardContentLessPadding>
        </Card>
    </>;
};

export default NotFound;
